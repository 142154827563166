import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import remarkGfm from "remark-gfm";

const MarkdownWrapper = styled.div`
  * {
    all: revert;
  }

  p {
    margin: 1em 0;
    font-size: 14px;
    line-height: 1.5;
    // margin-left: 40px;
  }

  a,
  a:visited,
  a:link {
    color: #1a9bdb;
    text-decoration: none;
    cursor: pointer;
    pointer-events: auto;
  }

  a:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  a[href*="http"],
  a[href*="https"] {
    color: #1a9bdb;
    display: inline-block;
    pointer-events: auto;
  }

  ul li {
    margin-left: 40px;
  }

  table {
    border-collapse: collapse;
    margin: 1em 0;
    width: 100%;
    font-size: 14px;
    border: 1px solid #ddd;
  }

  thead {
    background-color: #f8f9fa;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }

  th {
    font-weight: 600;
    border-bottom: 2px solid #ddd;
  }

  tr:nth-child(even) {
    background-color: #f8f9fa;
  }

  tr:hover {
    background-color: #f5f5f5;
  }
`;

const Markdown = ({ children }) => {
  const processMarkdown = (markdownContent) => {
    let processed = markdownContent.replace(
      /\[Source:\s*(https?:\/\/[^\s]+)\]/g,
      "[$1]($1)" // Converts it into the standard Markdown format
    );
    processed = processed.replace(
      /\*\*([^*:]+)\*\*\s*:?(?:\n\s*:)?/g,
      "**$1:**"
    );
    return processed;
  };
  const processedMarkdown = processMarkdown(children);

  return (
    <MarkdownWrapper>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ node, children, href, ...props }) => (
            <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
              {children}
            </a>
          ),
        }}
        // skipHtml={true}
        // className="markdown-body"
      >
        {processedMarkdown}
      </ReactMarkdown>
    </MarkdownWrapper>
  );
};

export default Markdown;
