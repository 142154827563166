/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import {
  MainPage,
  Headline6,
  Headline5,
  Body1,
  Primary,
  Subtitle1,
  Subtitle2,
  GreyBox,
  Button,
  ComponentContainer,
  Body2,
  Hexagon,
  TailwindButton,
  TailwindButtonOutline,
} from "../../components";
import { Grid, Select, MenuItem, Divider } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import GoogleMapReact from "google-map-react";
import MapMarker from "../../components/MapMarker";
import ContentLoader from "react-content-loader";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import {
  getProjectReports,
  getProjectNews,
  getProjectSocial,
  companyConnect,
  getProjectDetail,
  matchUsers,
  manageSub,
  apiRequest,
  getCompanyDetail,
  getReportDetail,
} from "../../store/Actions/AuthActions";

import { useFirestoreConnect } from "react-redux-firebase";
import Logo from "../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon1.png";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import Notes from "../../components/Notes";
import List from "../../components/List";
import EditNotes from "../../components/UserNotes/EditNotes";
import { Sparkline } from "../../components/SparkLine";
import { useMediaQuery } from "react-responsive";
import ProjectResources from "./components/Content/ProjectResources";
import ProjectAnnualProduction from "./components/Content/Production/ProjectAnnualProduction";
import ProjectQuartelyProduction from "./components/Content/QuaterlyProduction/ProjectQuarterlyProduction";
import LifeOfMine from "./components/Content/LifeOfMine";
import { InView } from "react-intersection-observer";
import StackChart from "./components/StackChart";
import { Popover, Transition } from "@headlessui/react";
import { Upgrade } from "@mui/icons-material";
import ReactGA from "react-ga4";
import ProjectDrillingResults from "./components/DrillingResults";
import Intercepts from "./components/Intercepts";
import ProjectReports from "./components/ProjectReports";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const ProjectDetail = ({
  getReportDetail,
  project_reports_loading,
  setSupscriptionMessage,
  matchUsers,
  setSupscriptionPopup,
  manageSub,
  activeUser,
  user_follow_list,
  history,
  project,
  getCompanyDetail,
  getProjectNews,
  getProjectSocial,
  companyConnect,
  getProjectDetail,
  reports,
  getProjectReports,
}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  // const eventTrack = (category, action, label) => {
  //   console.log("GA event:", category, ":", action, ":", label);
  //   ReactGA.event({
  //     id:project.id,
  //     category: category,
  //     action: action,
  //     label: label,
  //   });
  // };
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check if we're in an input field, textarea, or contentEditable element
      const isEditable =
        e.target.tagName === "INPUT" ||
        e.target.tagName === "TEXTAREA" ||
        e.target.isContentEditable;

      // If we're in an editable field and it's a single letter key (no Ctrl/Cmd)
      if (isEditable && !e.metaKey && !e.ctrlKey && !e.altKey) {
        // Check if it's a single letter (a-z or A-Z)
        if (/^[a-zA-Z]$/.test(e.key)) {
          e.stopPropagation();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown, true);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, true);
    };
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: project?.projectName,
      action: "project_viewed",
    });
    ReactGA.gtag("config", "G-JB0KC590Z4", {
      project_id: project?.id,
      project_name: project?.projectName,
      client: activeUser?.email,
    });
  }, [project]);

  function useOnScreen(ref, options) {
    const [isIntersecting, setIntersecting] = useState(null);

    const observer = new IntersectionObserver(([entry]) => {
      console.log(entry, options);
      if (entry.isIntersecting) {
        setIntersecting({ top: entry.boundingClientRect.top, name: options });
      }
    });

    useEffect(() => {
      if (ref.current) {
        observer.observe(ref.current);
      }

      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }, []);

    return isIntersecting;
  }
  const [visibleItersection, setvisibleItersection] =
    useState("overviewisVisible");
  const render_stuff = () => {
    let i = [
      {
        value: Math.abs(overviewRef.current?.getBoundingClientRect().top),
        name: "overviewisVisible",
      },
      {
        value: Math.abs(locationRef.current?.getBoundingClientRect().top),
        name: "locationisVisible",
      },
      {
        value: Math.abs(reportsRef.current?.getBoundingClientRect().top),
        name: "reportsisVisible",
      },
      {
        value: Math.abs(npvRef.current?.getBoundingClientRect().top),
        name: "reportsisVisible",
      },
      {
        value: Math.abs(RRRef.current?.getBoundingClientRect().top),
        name: "RRisVisible",
      },
      {
        value: Math.abs(productionRef.current?.getBoundingClientRect().top),
        name: "productionisVisble",
      },
    ];

    setvisibleItersection(_.sortBy(i, "value")[0].name);
  };

  const reportsRef = useRef();
  const npvRef = useRef();
  const overviewRef = useRef();
  const locationRef = useRef();
  const RRRef = useRef();
  const productionRef = useRef();
  const updatesRef = useRef();
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const location = useLocation();

  useEffect(() => {
    fetchData();
    // eventTrack("project_viewedsss", "");
    return () => {};
  }, [project]);
  const [lomp_data, setlomp_data] = useState(false);
  const fetchData = async () => {
    const data = await apiRequest
      .get(`projects/${project?.id}/life-of-mine-statistics`)
      .catch((err) => console.log(err));
    if (data && data.data) {
      let dates = Object.keys(_.groupBy(data.data.data, "as_of_date"));

      if (dates.length) {
        setlomp_data(true);
      } else {
        setlomp_data(false);
      }
    }
  };

  let id = location.pathname.replace("/project-detail/", "");
  const company = project ? project.companyData : null;
  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
  });
  const [center, setCenter] = useState({
    lat: parseFloat(project && project.lat ? project.lat : 35.7465),
    lng: parseFloat(project && project.lat ? project.lng : -39.4629),
  });
  useFirestoreConnect([
    {
      collection: "projects",
      doc: id,
      subcollections: [{ collection: "reports" }],
      storeAs: "reports",
    },
  ]);
  // const reports = useSelector(state => state.firestore.ordered.reports) || []
  useEffect(() => {
    resetCenter();
    return () => {};
  }, [project]);
  useEffect(() => {
    let id = location.pathname.replace("/project-detail/", "");

    getProjectDetail({ id, history });
  }, [location]);
  useEffect(() => {
    doStuff();
    return () => {};
  }, [history, project]);

  useEffect(() => {
    if (reports && reports.length) {
      setselectedReport(
        reports.reverse((a, b) =>
          moment(a.publication_date, "DD-MM-YYYY").diff(
            moment(b.publication_date, "DD-MM-YYYY")
          )
        )[0]
      );
    }
    return () => {};
  }, [reports]);

  const doStuff = () => {
    if (project) {
      setselectedReport(null);
      checkForNotes();
      getProjectNews({ searchTerm: project.reportName, datasource: "news" });
      getProjectSocial({
        searchTerm: project.reportName,
        datasource: "social",
      });
      getProjectReports(project.id);

      calculateTrend();
    } else {
      // history.push('browse')
    }
  };

  const calculateTrend = () => {
    let stock = [...project.stockHistory];
    let sortStock = _.sortBy(stock, function (dateObj) {
      return new Date(dateObj.date);
    });
    let diff = stock.length
      ? sortStock[sortStock.length - 1].open -
        sortStock[sortStock.length - 2].open
      : 0;
    let trendState = stock.length
      ? sortStock[sortStock.length - 1].open -
          sortStock[sortStock.length - 2].open >=
        0
        ? "up"
        : "down"
      : "up";
    let ratio = stock.length
      ? Math.abs(diff / sortStock[sortStock.length - 2].open) * 100
      : 0;

    setTrend({
      ratio: trendState === "up" ? ratio.toFixed(2) : `-${ratio.toFixed(2)}`,
      trend: trendState,
    });
  };
  const subscription = activeUser;
  const checkSubscription = async (url) => {
    if (subscription) {
      if (
        activeUser &&
        activeUser?.permissions?.includes("access_43101_portal")
      ) {
        // window.open(url, "_blank")
        getReportDetail({ id: url, history });
      } else {
        await setSupscriptionMessage("upgrade");
        setSupscriptionPopup(true);
      }
    } else {
      await setSupscriptionMessage("Login");
      setSupscriptionPopup(true);
    }
  };
  const resetCenter = () => {
    setCenter({
      lat: parseFloat(project && project.lat ? project.lat : 35.7465),
      lng: parseFloat(project && project.lat ? project.lng : -39.4629),
    });
  };

  const [selectedReport, setselectedReport] = useState();

  const handleSelectReport = (event) => {
    let report = reports.find(
      (item) =>
        `${item.name}-${moment(item.publication_date).format("YYYY-MM-DD")}` ===
        event.target.value
    );
    setselectedReport(report);
  };
  const [NotesOpen, setNotesOpen] = useState(false);
  const handleClickOpen = () => {
    setNotesOpen(true);
  };

  const handleClose = (value) => {
    setNotesOpen(false);
    checkForNotes();
  };
  const [ListOpen, setListOpen] = useState(false);
  const handleListOpen = () => {
    setListOpen(true);
  };

  const handleListClose = (value) => {
    setListOpen(false);
  };
  const [existingNote, setexistingNote] = useState(null);
  const checkForNotes = async () => {
    const response = await apiRequest
      .get(`/projects/${project.id}/notes`)
      .catch((error) => {});
    if (response && response.data.data) {
      setexistingNote(response.data.data);
    }
  };
  const states = ["Inferred", "Indicated", "Measured", "Probable", "Proven"];
  useEffect(() => {
    if (project) {
      setselected_mineral(project?.annual_prod_minerals?.[0]);
    }
    return () => {};
  }, [project]);

  const [selected_mineral, setselected_mineral] = useState(
    project?.annual_prod_minerals?.[0]
  );

  useEffect(() => {
    let id = location.pathname.replace("/project-detail/", "");
    const script = document.createElement("script");
    script.src =
      "https://copilot-projects.prospectormatch.com/copilot/index.js";
    script.async = true;
    document.body.appendChild(script);

    window.mountChainlitWidget({
      chainlitServer: "https://copilot-projects.prospectormatch.com/",
    });

    const handleKeyDown = (e) => {
      if (!e.metaKey && !e.ctrlKey && !e.altKey) {
        if (/^[a-zA-Z]$/.test(e.key)) {
          e.stopPropagation();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown, true);

    script.cleanup = () => {
      document.removeEventListener("keydown", handleKeyDown, true);
    };

    window.addEventListener("chainlit-call-fn", (e) => {
      const { name, args, callback } = e.detail;
      if (name === "getProjectId") {
        console.log(name, args);
        callback(id);
      } else if (name === "getAgent") {
        callback("Mining Project Specialist");
      }
    });

    return () => {
      window.unmountChainlitWidget();
      const chainlit_el = document.getElementById("chainlit-copilot");
      document.body.removeChild(chainlit_el);
    };
  }, [project]);

  return (
    <MainPage id="page" onScroll={render_stuff}>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={NotesOpen}
      >
        {existingNote ? (
          <EditNotes
            handleClose={handleClose}
            type={"project"}
            entity={existingNote}
          />
        ) : (
          <Notes handleClose={handleClose} type={"project"} entity={project} />
        )}
      </Dialog>
      <Dialog
        onClose={handleListClose}
        aria-labelledby="simple-dialog-title"
        open={ListOpen}
      >
        <List handleClose={handleListClose} type={"project"} entity={project} />
      </Dialog>
      <Grid container spacing={2}>
        {project ? (
          <>
            {!isTabletOrMobileDevice ? (
              <Grid item xs={2} className="project-detail-wrapper">
                <div
                  style={{
                    padding: "10px",
                    position: "sticky",
                    top: 0,
                    paddingTop: 0,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                      padding: "10px",
                    }}
                  >
                    <KeyboardBackspace
                      style={{ color: Primary }}
                      onClick={() => history.goBack()}
                    />
                  </div>

                  <div className="project-div">
                    <div
                      className="flex-boy-row"
                      style={{ marginBottom: "20px" }}
                    >
                      <div>
                        <Subtitle1 style={{ marginBottom: "7px" }}>
                          {project.reportName}
                        </Subtitle1>
                        <Subtitle2>{project.stage}</Subtitle2>
                      </div>
                      {project.countries.map((flag, index) => {
                        let flagSource;
                        try {
                          flagSource = require(`../../assets/ProspectorDesignAssets/Flags/${flag
                            .toLowerCase()
                            .replace(" ", "-")}.png`);
                        } catch {
                          flagSource = require(`../../assets/ProspectorDesignAssets/Flags/usa.png`);
                        }
                        return (
                          <img
                            onClick={() => {
                              let com = {
                                name: flag,
                                type: "country",
                              };
                            }}
                            key="flag"
                            className="project-flag"
                            style={{ height: "25px" }}
                            src={flagSource}
                            alt="region"
                          />
                        );
                      })}
                    </div>
                    <div
                      className="flex-boy-row"
                      style={{ justifyContent: "normal", flexWrap: "wrap" }}
                    >
                      {[...new Set(project.minerals)]
                        .filter(
                          (item) =>
                            item !== "Not" &&
                            item !== "Combined" &&
                            item !== "Metric Tonnes"
                        )
                        .map((min, index) => {
                          return (
                            <Hexagon
                              key={index}
                              width={28}
                              style={{ fontSize: "0.6rem" }}
                            >
                              {elements.find(
                                (item) =>
                                  item.name.toLowerCase() ===
                                  min.trim().toLowerCase()
                              )
                                ? elements
                                    .find(
                                      (item) =>
                                        item.name.toLowerCase() ===
                                        min.trim().toLowerCase()
                                    )
                                    .chemical_symbol.substring(0, 4)
                                : min.substring(0, 4)}
                            </Hexagon>
                          );
                        })}
                    </div>
                    <div
                      className="flex-boy-row"
                      style={{
                        marginTop: "5px",
                        justifyContent: "normal",
                        flexWrap: "wrap",
                      }}
                    >
                      {states.map((status, index) => {
                        return (
                          <GreyBox
                            className="text-sm"
                            style={{
                              margin: "2px",
                              width: isTabletOrMobileDevice ? "42px" : "80px",

                              fontSize: "14px",
                              backgroundColor:
                                project.mineralEstimate &&
                                project.mineralEstimate
                                  .toString()
                                  ?.includes(status)
                                  ? "#1A9BDB"
                                  : "gainsboro",
                              color:
                                project.mineralEstimate &&
                                project.mineralEstimate
                                  .toString()
                                  ?.includes(status)
                                  ? "white"
                                  : "#454555",
                              textTransform: isTabletOrMobileDevice
                                ? "uppercase"
                                : "capitalize",
                              textAlign: "center",
                            }}
                            key={index}
                          >
                            {isTabletOrMobileDevice
                              ? status === "Probable" || status === "Proven"
                                ? status.substring(0, 4)
                                : status.substring(0, 3)
                              : status}
                          </GreyBox>
                        );
                      })}
                    </div>
                  </div>

                  <Divider />
                  <div
                    className="flex-boy-row"
                    style={{ marginTop: "20px", padding: "20px" }}
                  >
                    <TailwindButton
                      onClick={() =>
                        activeUser
                          ? setNotesOpen(true)
                          : history.push("/auth/login")
                      }
                      text={`${existingNote ? "Edit" : "+ Add"} Notes`}
                    />

                    <div className="my-2">
                      {activeUser &&
                      activeUser?.permissions?.includes("follow_resources") ? (
                        user_follow_list?.includes(project.id) ? (
                          <TailwindButton
                            text="Following"
                            onClick={() => setListOpen(true)}
                          />
                        ) : user_follow_list
                            ?.map((item) => item.list_entries)
                            ?.flat()
                            ?.includes(project.id) ? (
                          <TailwindButton
                            text="Following"
                            onClick={() => setListOpen(true)}
                          />
                        ) : (
                          <TailwindButtonOutline
                            onClick={() => setListOpen(true)}
                            text="Follow"
                          />
                        )
                      ) : (
                        <TailwindButtonOutline
                          onClick={() =>
                            activeUser
                              ? manageSub()
                              : history.push("/auth/login")
                          }
                          text="Connect"
                        />
                      )}
                    </div>
                  </div>

                  <div className="page-anchors">
                    <Subtitle1
                      color={
                        visibleItersection === "overviewisVisible" && Primary
                      }
                      style={{ marginBottom: "20px" }}
                      onClick={(e) =>
                        overviewRef.current?.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      1. Project Overview
                    </Subtitle1>
                    <Subtitle1
                      style={{ marginBottom: "20px" }}
                      color={
                        visibleItersection === "locationisVisible" && Primary
                      }
                      onClick={(e) =>
                        locationRef.current?.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      2. Location and Ownership
                    </Subtitle1>
                    <div>
                      <Subtitle1
                        color={
                          visibleItersection === "reportsisVisible" && Primary
                        }
                        style={{ marginBottom: "20px" }}
                        onClick={(e) =>
                          reportsRef.current?.scrollIntoView({
                            behavior: "smooth",
                          })
                        }
                      >
                        3. Technical Reports
                      </Subtitle1>
                      <Subtitle1
                        color={
                          visibleItersection === "reportsisVisible" && Primary
                        }
                        style={{ marginBottom: "20px", marginLeft: "20px" }}
                        onClick={(e) =>
                          npvRef.current?.scrollIntoView({
                            behavior: "smooth",
                          })
                        }
                      >
                        &#8226; LOMP/NPV Calculator
                      </Subtitle1>
                    </div>

                    {project.r_r_minerals.length > 0 && (
                      <Subtitle1
                        style={{ marginBottom: "20px" }}
                        color={visibleItersection === "RRisVisible" && Primary}
                        onClick={(e) =>
                          RRRef.current?.scrollIntoView({
                            behavior: "smooth",
                          })
                        }
                      >
                        4. Resources and Reserves
                      </Subtitle1>
                    )}
                    {project.annual_prod_minerals.length > 0 && (
                      <Subtitle1
                        style={{ marginBottom: "20px" }}
                        color={
                          visibleItersection === "productionisVisble" && Primary
                        }
                        onClick={(e) =>
                          productionRef.current?.scrollIntoView({
                            behavior: "smooth",
                          })
                        }
                      >
                        5. Production
                      </Subtitle1>
                    )}
                  </div>
                </div>
              </Grid>
            ) : (
              <Popover
                className="relative m-2 p-4 z-10 "
                style={{ padding: "10px", position: "sticky", top: 0 }}
              >
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",

                        "group p-2 bg-white rounded-md shadow-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 left-full transform -translate-x-1/10 mt-3 px-2 w-screen max-w-xs sm:px-0">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-2 py-2 sm:gap-8 ">
                            <div
                              style={{
                                padding: "10px",
                                position: "sticky",
                                top: 0,
                                paddingTop: 0,
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  padding: "10px",
                                }}
                              >
                                <KeyboardBackspace
                                  style={{ color: Primary }}
                                  onClick={() => history.goBack()}
                                />
                              </div>

                              <div className="project-div">
                                <div
                                  className="flex-boy-row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <div>
                                    <Subtitle1 style={{ marginBottom: "7px" }}>
                                      {project.reportName}
                                    </Subtitle1>
                                    <Subtitle2>{project.stage}</Subtitle2>
                                  </div>
                                  {project.countries.map((flag, index) => {
                                    let flagSource;
                                    try {
                                      flagSource = require(`../../assets/ProspectorDesignAssets/Flags/${flag
                                        .toLowerCase()
                                        .replace(" ", "-")}.png`);
                                    } catch {
                                      flagSource = require(`../../assets/ProspectorDesignAssets/Flags/usa.png`);
                                    }
                                    return (
                                      <img
                                        onClick={() => {
                                          let com = {
                                            name: flag,
                                            type: "country",
                                          };
                                        }}
                                        key="flag"
                                        className="project-flag"
                                        style={{ height: "25px" }}
                                        src={flagSource}
                                        alt="region"
                                      />
                                    );
                                  })}
                                </div>
                                <div
                                  className="flex-boy-row"
                                  style={{
                                    justifyContent: "normal",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {[...new Set(project.minerals)]
                                    .filter(
                                      (item) =>
                                        item !== "Not" &&
                                        item !== "Combined" &&
                                        item !== "Metric Tonnes"
                                    )
                                    .map((min, index) => {
                                      return (
                                        <Hexagon
                                          key={index}
                                          width={28}
                                          style={{ fontSize: "0.6rem" }}
                                        >
                                          {elements.find(
                                            (item) =>
                                              item.name.toLowerCase() ===
                                              min.trim().toLowerCase()
                                          )
                                            ? elements
                                                .find(
                                                  (item) =>
                                                    item.name.toLowerCase() ===
                                                    min.trim().toLowerCase()
                                                )
                                                .chemical_symbol.substring(0, 4)
                                            : min.substring(0, 4)}
                                        </Hexagon>
                                      );
                                    })}
                                </div>
                                <div
                                  className="flex-boy-row"
                                  style={{
                                    marginTop: "5px",
                                    justifyContent: "normal",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {states.map((status, index) => {
                                    return (
                                      <GreyBox
                                        className="text-sm"
                                        style={{
                                          margin: "2px",
                                          width: isTabletOrMobileDevice
                                            ? "42px"
                                            : "80px",

                                          fontSize: "14px",
                                          backgroundColor:
                                            project.mineralEstimate &&
                                            project.mineralEstimate
                                              .toString()
                                              ?.includes(status)
                                              ? "#1A9BDB"
                                              : "gainsboro",
                                          color:
                                            project.mineralEstimate &&
                                            project.mineralEstimate
                                              .toString()
                                              ?.includes(status)
                                              ? "white"
                                              : "#454555",
                                          textTransform: isTabletOrMobileDevice
                                            ? "uppercase"
                                            : "capitalize",
                                          textAlign: "center",
                                        }}
                                        key={index}
                                      >
                                        {isTabletOrMobileDevice
                                          ? status === "Probable" ||
                                            status === "Proven"
                                            ? status.substring(0, 4)
                                            : status.substring(0, 3)
                                          : status}
                                      </GreyBox>
                                    );
                                  })}
                                </div>
                              </div>

                              <Divider />
                              <div
                                className="flex-boy-row flex-wrap"
                                style={{ marginTop: "20px", padding: "20px" }}
                              >
                                <TailwindButton
                                  onClick={() =>
                                    activeUser
                                      ? setNotesOpen(true)
                                      : history.push("/auth/login")
                                  }
                                  text={`${
                                    existingNote ? "Edit" : "+ Add"
                                  } Notes`}
                                />

                                <div className="my-2">
                                  {activeUser &&
                                  activeUser?.permissions?.includes(
                                    "follow_resources"
                                  ) ? (
                                    user_follow_list?.includes(project.id) ? (
                                      <TailwindButton
                                        text="Following"
                                        onClick={() => setListOpen(true)}
                                      />
                                    ) : user_follow_list
                                        ?.map((item) => item.list_entries)
                                        ?.flat()
                                        ?.includes(project.id) ? (
                                      <TailwindButton
                                        text="Following"
                                        onClick={() => setListOpen(true)}
                                      />
                                    ) : (
                                      <TailwindButtonOutline
                                        onClick={() => setListOpen(true)}
                                        text="Follow"
                                      />
                                    )
                                  ) : (
                                    <TailwindButtonOutline
                                      onClick={() =>
                                        activeUser
                                          ? manageSub()
                                          : history.push("/auth/login")
                                      }
                                      text="Connect"
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="page-anchors">
                                <Subtitle1
                                  color={
                                    visibleItersection ===
                                      "overviewisVisible" && Primary
                                  }
                                  style={{ marginBottom: "20px" }}
                                  onClick={(e) =>
                                    overviewRef.current?.scrollIntoView({
                                      behavior: "smooth",
                                    })
                                  }
                                >
                                  1. Project Overview
                                </Subtitle1>
                                <Subtitle1
                                  style={{ marginBottom: "20px" }}
                                  color={
                                    visibleItersection ===
                                      "locationisVisible" && Primary
                                  }
                                  onClick={(e) =>
                                    locationRef.current?.scrollIntoView({
                                      behavior: "smooth",
                                    })
                                  }
                                >
                                  2. Location and Ownership
                                </Subtitle1>
                                <div>
                                  <Subtitle1
                                    color={
                                      visibleItersection ===
                                        "reportsisVisible" && Primary
                                    }
                                    style={{ marginBottom: "20px" }}
                                    onClick={(e) =>
                                      reportsRef.current?.scrollIntoView({
                                        behavior: "smooth",
                                      })
                                    }
                                  >
                                    3. Technical Reports
                                  </Subtitle1>
                                  <Subtitle1
                                    color={
                                      visibleItersection ===
                                        "reportsisVisible" && Primary
                                    }
                                    style={{
                                      marginBottom: "20px",
                                      marginLeft: "20px",
                                    }}
                                    onClick={(e) =>
                                      npvRef.current?.scrollIntoView({
                                        behavior: "smooth",
                                      })
                                    }
                                  >
                                    &#8226; LOMP/NPV Calculator
                                  </Subtitle1>
                                </div>

                                {project.r_r_minerals.length > 0 && (
                                  <Subtitle1
                                    style={{ marginBottom: "20px" }}
                                    color={
                                      visibleItersection === "RRisVisible" &&
                                      Primary
                                    }
                                    onClick={(e) =>
                                      RRRef.current?.scrollIntoView({
                                        behavior: "smooth",
                                      })
                                    }
                                  >
                                    4. Resources and Reserves
                                  </Subtitle1>
                                )}
                                {project.annual_prod_minerals.length > 0 && (
                                  <Subtitle1
                                    style={{ marginBottom: "20px" }}
                                    color={
                                      visibleItersection ===
                                        "productionisVisble" && Primary
                                    }
                                    onClick={(e) =>
                                      productionRef.current?.scrollIntoView({
                                        behavior: "smooth",
                                      })
                                    }
                                  >
                                    5. Production
                                  </Subtitle1>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            )}
          </>
        ) : null}
        {project ? (
          <Grid
            item
            xs={12}
            lg={10}
            xl={10}
            container
            style={{ paddingRight: "2em" }}
          >
            <div style={{ textAlign: "left", width: "100%" }}>
              <ComponentContainer ref={overviewRef}>
                <div
                  className="flex-boy-row flex-boy-row-exchanges"
                  style={{ alignItems: "normal" }}
                >
                  <div style={{ flex: 1 }}>
                    <div
                      className="flex-boy-row"
                      style={{ justifyContent: "normal" }}
                    >
                      <Headline5>{project.reportName}</Headline5>
                    </div>

                    <Body1
                      style={{
                        textAlign: "justify",
                        marginBottom: "100px",
                        marginRight: "20px",
                        marginTop: "15px",
                      }}
                    >
                      {project.reportSummary}
                    </Body1>
                  </div>
                  <div style={{ borderLeft: "1px solid gainsboro", flex: 1 }}>
                    {activeUser &&
                    subscription &&
                    activeUser?.permissions?.includes(
                      "view_project_details"
                    ) ? (
                      <div className="statistics">
                        <div className="statistic">
                          <Subtitle1>Primary Metal</Subtitle1>
                          <Subtitle2>{project.minerals?.[0]}</Subtitle2>
                        </div>
                        <div className="statistic">
                          <Subtitle1>Minerals</Subtitle1>
                          <Subtitle2>{project.minerals.toString()}</Subtitle2>
                        </div>
                        <div className="statistic" style={{ width: "100%" }}>
                          <Subtitle1>Mineral Estimate</Subtitle1>
                          <Subtitle2>
                            {project.mineralEstimate.toString()}
                          </Subtitle2>
                        </div>
                        <div className="statistic">
                          <Subtitle1>Stage</Subtitle1>
                          <Subtitle2>{project.stage}</Subtitle2>
                        </div>
                        {reports && reports.length ? (
                          <div className="statistic">
                            <Subtitle1>Last Technical Report</Subtitle1>
                            <Subtitle2>{project.report_date}</Subtitle2>
                          </div>
                        ) : null}
                        <div className="statistic">
                          <Subtitle1>Country</Subtitle1>
                          <Subtitle2>{project.countries}</Subtitle2>
                        </div>
                        <div className="statistic">
                          <Subtitle1>Region</Subtitle1>
                          <Subtitle2>{project.region}</Subtitle2>
                        </div>
                      </div>
                    ) : (
                      <div className="blur-content w-full h-fit min-h-[500px] relative">
                        <div
                          className="subscription-box absolute left-[50%] top-[50%] z-10"
                          style={{ height: "400px" }}
                        >
                          <div className="sub-box">
                            {activeUser ? (
                              <Subtitle1 color="white">
                                {" "}
                                Please upgrade your subscription to view this
                                content{" "}
                              </Subtitle1>
                            ) : (
                              <Subtitle1>
                                {" "}
                                Please Login to view this content{" "}
                              </Subtitle1>
                            )}
                            <Button
                              onClick={() =>
                                activeUser
                                  ? manageSub()
                                  : history.push("/auth/login")
                              }
                            >
                              {!activeUser ? "Login" : "Upgrade"}
                            </Button>
                          </div>
                        </div>

                        <div className="statistics blur-sm">
                          <div className="statistic">
                            <Subtitle1>Primary Metal</Subtitle1>
                            <Subtitle2>{project.minerals?.[0]}</Subtitle2>
                          </div>
                          <div className="statistic">
                            <Subtitle1>Minerals</Subtitle1>
                            <Subtitle2>{project.minerals.toString()}</Subtitle2>
                          </div>
                          <div className="statistic" style={{ width: "100%" }}>
                            <Subtitle1>Mineral Estimate</Subtitle1>
                            <Subtitle2>
                              {project.mineralEstimate.toString()}
                            </Subtitle2>
                          </div>
                          <div className="statistic">
                            <Subtitle1>Stage</Subtitle1>
                            <Subtitle2>{project.stage}</Subtitle2>
                          </div>
                          {reports && reports.length ? (
                            <div className="statistic">
                              <Subtitle1>Last Technical Report</Subtitle1>
                              <Subtitle2>{project.report_date}</Subtitle2>
                            </div>
                          ) : null}
                          <div className="statistic">
                            <Subtitle1>Country</Subtitle1>
                            <Subtitle2>{project.countries}</Subtitle2>
                          </div>
                          <div className="statistic">
                            <Subtitle1>Region</Subtitle1>
                            <Subtitle2>{project.region}</Subtitle2>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ComponentContainer>
              {/* <ComponentContainer style={{disply}} ref={updatesRef}>

                                </ComponentContainer> */}
              <ComponentContainer ref={locationRef}>
                <Headline5 style={{ marginBottom: "10px" }}>
                  Location and Ownership
                </Headline5>

                {project ? (
                  <div className="flex flex-row h-[400px] mb-2">
                    <div className="basis-3/4">
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyAjl3X5q6XmuETXG8wNyCUb2kOD0IK9PNE",
                        }}
                        center={center}
                        defaultZoom={5}
                      >
                        {project.lat ? (
                          <MapMarker
                            project={project}
                            lat={project.lat}
                            lng={project.lng}
                          />
                        ) : null}
                      </GoogleMapReact>
                    </div>
                    <div className="basis-1/4">
                      <StackChart
                        data={project.investors.filter(
                          (item) => item.ownership.ownership_type === "Direct"
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ContentLoader
                      viewBox="0 0 800 300"
                      height={300}
                      width={600}
                    >
                      <rect
                        x="0"
                        y="0"
                        rx="0"
                        ry="0"
                        width="800"
                        height="600"
                      />
                    </ContentLoader>
                  </div>
                )}
                <Headline5 style={{ marginBottom: "20px" }}>
                  Project Owners
                </Headline5>
                {project.investors.map((investor) => {
                  return (
                    <div className="w-full flex flex-row">
                      <div className="company-logo aspect-square  flex flex-col justify-center m-2">
                        <img
                          style={{
                            width: "80px",
                            maxWidth: "80px",
                          }}
                          src={
                            investor.logo?.replace(
                              "https://prospector-public-assets.s3.amazonaws.com/https%3A//",
                              "https://"
                            ) || Logo
                          }
                          alt="company logo"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = Logo;
                          }}
                        />
                      </div>
                      <div style={{ padding: "5px", flex: 1 }}>
                        <div
                          className="flex-boy-row "
                          style={{
                            width: "100%",
                            marginBottom: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <div>
                            <div className="company-ticker">
                              <Subtitle1
                                onClick={() =>
                                  getCompanyDetail({ id: investor.id, history })
                                }
                              >
                                {investor.name}{" "}
                                {investor.ticker
                                  ? `(${investor.ticker}.${investor.exchange})`
                                  : "(Private or State Owned)"}
                              </Subtitle1>
                            </div>
                          </div>

                          <div className="flex flex-row" key={investor.id}>
                            <h6 className="mr-4 text-sm font-bold">
                              {investor.ownership.net_interest
                                ? `${investor.ownership.net_interest}%`
                                : null}{" "}
                              {investor.ownership.ownership_type
                                ? `- ${investor.ownership.ownership_type}`
                                : null}
                            </h6>
                          </div>
                        </div>
                        <Body1 style={{ marginBottom: "20px" }}>
                          {investor.description}
                        </Body1>

                        <div
                          className="flex-boy-row"
                          style={{ marginBottom: "20px" }}
                        >
                          <div
                            className="flex-boy-row"
                            style={{
                              justifyContent: "normal",
                              flexWrap: "wrap",
                            }}
                          >
                            {[...new Set(investor.minerals)]
                              .filter(
                                (item) =>
                                  item !== "Not" &&
                                  item !== "Combined" &&
                                  item !== "Metric Tonnes"
                              )
                              .map((min, index) => {
                                return (
                                  <Hexagon
                                    key={index}
                                    width={28}
                                    style={{ fontSize: "0.6rem" }}
                                  >
                                    {elements.find(
                                      (item) =>
                                        item.name.toLowerCase() ===
                                        min.trim().toLowerCase()
                                    )
                                      ? elements
                                          .find(
                                            (item) =>
                                              item.name.toLowerCase() ===
                                              min.trim().toLowerCase()
                                          )
                                          .chemical_symbol.substring(0, 4)
                                      : min.substring(0, 4)}
                                  </Hexagon>
                                );
                              })}
                          </div>
                          <div style={{ textAlign: "left" }}>
                            <GreyBox
                              style={{
                                width: "100px",
                                marginLeft: "100px",
                                fontSize: "14px",
                              }}
                            >
                              {investor.projects_count} Projects
                            </GreyBox>
                          </div>
                        </div>

                        <Divider />
                      </div>
                    </div>
                  );
                })}
              </ComponentContainer>

              <ComponentContainer>
                <Headline6 style={{ marginBottom: "20px" }}>
                  Technical Reports
                </Headline6>

                {!project_reports_loading && reports && reports.length ? (
                  <div className="flex-boy-row" ref={reportsRef}>
                    <Select
                      style={{ width: "400px" }}
                      value={
                        selectedReport
                          ? `${selectedReport.name}-${moment(
                              selectedReport.publication_date
                            ).format("YYYY-MM-DD")}`
                          : ""
                      }
                      onChange={(e) => handleSelectReport(e)}
                    >
                      {reports.map((item) => (
                        <MenuItem
                          value={`${item.name}-${moment(
                            item.publication_date
                          ).format("YYYY-MM-DD")}`}
                        >{`${item.name}-${moment(item.publication_date).format(
                          "YYYY-MM-DD"
                        )}`}</MenuItem>
                      ))}
                    </Select>
                    <TailwindButton
                      onClick={() => checkSubscription(selectedReport.id)}
                      text="View Report"
                    />
                  </div>
                ) : null}

                {!project_reports_loading ? (
                  selectedReport ? (
                    <div
                      key={selectedReport.id}
                      className="project-div"
                      style={{ position: "relative" }}
                    >
                      <div className="flex-boy-row">
                        <Headline6>{selectedReport.name}</Headline6>
                      </div>

                      <div
                        className="flex-boy-row"
                        style={{
                          marginTop: "5px",
                          height: "fit-content",
                          justifyContent: "normal",
                        }}
                      >
                        <div
                          onClick={() =>
                            activeUser
                              ? checkSubscription(selectedReport.external_id)
                              : history.push("/auth/login")
                          }
                          className="project-image  flex-boy-row"
                          style={{
                            backgroundImage:
                              "url('../../assets/ProspectorDesignAssets/Climate Smart Mining.png')",
                          }}
                        >
                          <img
                            style={{ width: "90px" }}
                            src={
                              selectedReport.cover_page ||
                              require("../../assets/ProspectorDesignAssets/Climate Smart Mining.png")
                            }
                            alt="project"
                          />
                        </div>

                        <div style={{ marginleft: "120px !important" }}>
                          <Body1
                            color="#00000099"
                            className="project-description"
                            style={{
                              fontSize: "14px",
                              lineHeight: "1.2rem",
                              WebkitLineClamp: 5,
                              width: "100%",
                            }}
                          >
                            {selectedReport.summary}
                          </Body1>
                          <div className="flex-boy-row">
                            <Headline6
                              style={{ margintop: "12px !important" }}
                              onClick={() =>
                                activeUser
                                  ? checkSubscription(selectedReport.id)
                                  : history.push("/auth/login")
                              }
                              color={Primary}
                            >
                              More
                            </Headline6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="flex-boy-column"
                      style={{ alignItems: "center" }}
                    >
                      <img
                        src={require("../../assets/ProspectorDesignAssets/empty_image.png")}
                        alt="empty"
                        style={{
                          height: "100px",
                          marginTop: "50px",
                          marginBottom: "15px",
                        }}
                      />
                      <Headline6 color="gainsboro">
                        Project has no available reports.{" "}
                      </Headline6>
                    </div>
                  )
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ContentLoader
                      viewBox="0 0 600 200"
                      height={200}
                      width={600}
                    >
                      <rect
                        x="20"
                        y="8"
                        rx="0"
                        ry="0"
                        width="100"
                        height="100"
                      />
                      <rect
                        x="20"
                        y="120"
                        rx="0"
                        ry="0"
                        width="100"
                        height="10"
                      />
                      <rect
                        x="130"
                        y="8"
                        rx="0"
                        ry="0"
                        width="400"
                        height="15"
                      />
                      <rect
                        x="130"
                        y="30"
                        rx="0"
                        ry="0"
                        width="400"
                        height="15"
                      />
                      <rect
                        x="130"
                        y="52"
                        rx="0"
                        ry="0"
                        width="200"
                        height="15"
                      />
                    </ContentLoader>
                    <ContentLoader
                      viewBox="0 0 600 200"
                      height={200}
                      width={600}
                    >
                      <rect
                        x="20"
                        y="8"
                        rx="0"
                        ry="0"
                        width="100"
                        height="100"
                      />
                      <rect
                        x="20"
                        y="120"
                        rx="0"
                        ry="0"
                        width="100"
                        height="10"
                      />
                      <rect
                        x="130"
                        y="8"
                        rx="0"
                        ry="0"
                        width="400"
                        height="15"
                      />
                      <rect
                        x="130"
                        y="30"
                        rx="0"
                        ry="0"
                        width="400"
                        height="15"
                      />
                      <rect
                        x="130"
                        y="52"
                        rx="0"
                        ry="0"
                        width="200"
                        height="15"
                      />
                    </ContentLoader>
                    <ContentLoader
                      viewBox="0 0 600 200"
                      height={200}
                      width={600}
                    >
                      <rect
                        x="20"
                        y="8"
                        rx="0"
                        ry="0"
                        width="100"
                        height="100"
                      />
                      <rect
                        x="20"
                        y="120"
                        rx="0"
                        ry="0"
                        width="100"
                        height="10"
                      />
                      <rect
                        x="130"
                        y="8"
                        rx="0"
                        ry="0"
                        width="400"
                        height="15"
                      />
                      <rect
                        x="130"
                        y="30"
                        rx="0"
                        ry="0"
                        width="400"
                        height="15"
                      />
                      <rect
                        x="130"
                        y="52"
                        rx="0"
                        ry="0"
                        width="200"
                        height="15"
                      />
                    </ContentLoader>
                  </div>
                )}
                <Divider />

                {activeUser?.permissions?.includes(
                  "view_resource_reserve_charts"
                ) ? (
                  <div className="w-full" ref={npvRef}>
                    <LifeOfMine />
                  </div>
                ) : (
                  <div
                    ref={npvRef}
                    className="blur-content w-full h-fit min-h-[500px] relative"
                  >
                    <div
                      className="subscription-box absolute left-[50%] top-[50%] z-10"
                      style={{ height: "400px" }}
                    >
                      <div className="sub-box">
                        {activeUser ? (
                          <Subtitle1 color="white">
                            {" "}
                            Please upgrade your subscription to view this
                            content{" "}
                          </Subtitle1>
                        ) : (
                          <Subtitle1>
                            {" "}
                            Please Login to view this content{" "}
                          </Subtitle1>
                        )}
                        <Button
                          onClick={() =>
                            activeUser
                              ? manageSub()
                              : history.push("/auth/login")
                          }
                        >
                          {!activeUser ? "Login" : "Upgrade"}
                        </Button>
                      </div>
                    </div>

                    <div className="blur-sm">
                      <img
                        src={require("../../assets/icon/lifeOfMine.png")}
                        alt="life"
                      />
                    </div>
                  </div>
                )}
              </ComponentContainer>
              <ComponentContainer>
                <Headline5>Project Fillings</Headline5>
                <ProjectReports />
              </ComponentContainer>

              {project.r_r_minerals.length > 0 &&
              activeUser?.permissions?.includes(
                "view_resource_reserve_charts"
              ) ? (
                <>
                  {project.r_r_minerals.length > 0 && (
                    <ComponentContainer ref={RRRef}>
                      <ProjectResources />
                    </ComponentContainer>
                  )}

                  {(project.annual_prod_minerals.length > 0 ||
                    project.quarterly_prod_minerals.length > 0) && (
                    <ComponentContainer ref={productionRef}>
                      <Headline5>Production</Headline5>

                      {project.annual_prod_minerals.length > 0 && (
                        <ProjectAnnualProduction mineral={selected_mineral} />
                      )}
                      {project.quarterly_prod_minerals.length > 0 && (
                        <ProjectQuartelyProduction mineral={selected_mineral} />
                      )}
                    </ComponentContainer>
                  )}
                </>
              ) : project.r_r_minerals.length > 0 ? (
                <div className="blur-content w-full h-fit min-h-[500px] relative">
                  <div
                    className="subscription-box absolute left-[50%] top-[50%] z-10"
                    style={{ height: "400px" }}
                  >
                    <div className="sub-box">
                      {activeUser ? (
                        <Subtitle1 color="white">
                          {" "}
                          Please upgrade your subscription to view this content{" "}
                        </Subtitle1>
                      ) : (
                        <Subtitle1>
                          {" "}
                          Please Login to view this content{" "}
                        </Subtitle1>
                      )}
                      <Button
                        onClick={() =>
                          activeUser
                            ? history.push("/upgrade")
                            : history.push("/auth/login")
                        }
                      >
                        {!activeUser ? "Login" : "Upgrade"}
                      </Button>
                    </div>
                  </div>

                  <div className="blur-sm w-full">
                    {project.r_r_minerals.length > 0 && (
                      <ComponentContainer ref={RRRef}>
                        <ProjectResources />
                      </ComponentContainer>
                    )}
                    {/* {(project.annual_prod_minerals.length > 0 ||
                      project.quarterly_prod_minerals.length > 0) && (
                      <ComponentContainer ref={productionRef}>
                        <Headline5>Production</Headline5>
                        <div className="flex flex-row  min-w-[50%] mt-4">
                          {project &&
                            _.uniq([
                              ...project.annual_prod_minerals,
                              ...project.quarterly_prod_minerals,
                            ]).map((min) => {
                              let mineral = elements.find(
                                (item) =>
                                  item.name.toLowerCase() ===
                                  min.trim().toLowerCase()
                              )
                                ? elements
                                    .find(
                                      (item) =>
                                        item.name.toLowerCase() ===
                                        min.trim().toLowerCase()
                                    )
                                    .chemical_symbol.substring(0, 3)
                                : min.substring(0, 3);

                              return (
                                <div
                                  className={`mineral-div ${
                                    selected_mineral === min
                                      ? "bg-emerald-500"
                                      : "bg-slate-200"
                                  } cursor-pointer flex-boy-row h-[50px] w-[50px] mr-[20px] font-bold rounded`}
                                  onClick={() => setselected_mineral(min)}
                                >
                                  <Headline6
                                    style={{ textAlign: "center", flex: "1" }}
                                    color={
                                      selected_mineral === min
                                        ? "white"
                                        : "grey"
                                    }
                                  >
                                    {mineral}{" "}
                                  </Headline6>
                                </div>
                              );
                            })}
                        </div>
                        {project.annual_prod_minerals.length > 0 && (
                          <ProjectAnnualProduction mineral={selected_mineral} />
                        )}
                        {project.quarterly_prod_minerals.length > 0 && (
                          <ProjectQuartelyProduction
                            mineral={selected_mineral}
                          />
                        )}
                      </ComponentContainer>
                    )} */}
                  </div>
                </div>
              ) : null}
              <div className="w-full">
                <ComponentContainer>
                  <ProjectDrillingResults />
                  <Intercepts />
                </ComponentContainer>
              </div>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </MainPage>
  );
};

const mapStateToProps = (state) => ({
  project: state.authReducer.selectedProject,
  project_reports_loading: state.authReducer.project_reports_loading,
  reports: state.authReducer.selectedCompany_reports,
  activeUser: state.authReducer.activeUser,
  user_follow_list: state.authReducer.lists,
});

const mapDispatchToProps = (dispatch) => ({
  getProjectReports: (data) => dispatch(getProjectReports(data)),
  getProjectSocial: (data) => dispatch(getProjectSocial(data)),
  getProjectNews: (data) => dispatch(getProjectNews(data)),
  companyConnect: (data) => dispatch(companyConnect(data)),
  getProjectDetail: (data) => dispatch(getProjectDetail(data)),
  getReportDetail: (data) => dispatch(getReportDetail(data)),
  getCompanyDetail: (data) => dispatch(getCompanyDetail(data)),
  manageSub: (data) => dispatch(manageSub(data)),
  matchUsers: (data) => dispatch(matchUsers(data)),
  setSupscriptionPopup: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_POPUP", payload: data }),
  setSupscriptionMessage: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_MESSAGE", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
