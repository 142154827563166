import React, { useState, useEffect } from "react";
import * as CPS from "../ChatPage.styles";
import { Chat, Delete, Edit } from "@material-ui/icons";
import { useChatContext } from "../ChatContext/ChatContext";
import { FaSpinner, FaCheck, FaTimes } from "react-icons/fa";

const API_HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
};

const ChatSidebar = () => {
  const {
    currentConversationId,
    setCurrentConversationId,
    setIsCreatingNewChat,
    conversationHistory,
    setConversationHistory,
  } = useChatContext();

  const [editingConversationId, setEditingConversationId] = useState(null);
  const [editingConversationName, setEditingConversationName] = useState("");
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [deletingConversationId, setDeletingConversationId] = useState(null);
  const [isDeletingConversation, setIsDeletingConversation] = useState(false);
  const [isFetchingConversations, setIsFetchingConversations] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const DateGroups = {
    today: "Today",
    yesterday: "Yesterday",
    lastMonth: "Last 30 Days",
    [new Date().toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    })]: (date) => {
      return date;
    },
  };

  const fetchUserConversations = async () => {
    try {
      setIsFetchingConversations(true);
      const response = await fetch(`${API_HOSTNAME}/chat/user_conversations`, {
        method: "GET",
        headers: getHeaders(),
        credentials: "include", // This is important for CORS requests
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setConversationHistory(data);
    } catch (error) {
      console.error("Error fetching conversation history:", error);
    } finally {
      setIsFetchingConversations(false);
      displayedConversations();
    }
  };

  const getDateString = (dateString) => {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };

  const saveConversationName = async (conversationId) => {
    setIsUpdatingName(true);
    try {
      const oldName =
        conversationHistory.find(
          (conv) => conv.conversation_id === conversationId
        )?.conversation_name || "";
      setConversationHistory((prevHistory) =>
        prevHistory.map((conv) =>
          conv.conversation_id === conversationId
            ? { ...conv, conversation_name: editingConversationName }
            : conv
        )
      );

      const resp = await fetch(`${API_HOSTNAME}/chat/conversation_name`, {
        method: "POST",
        headers: getHeaders(),
        credentials: "include",
        body: JSON.stringify({
          conversation_id: conversationId,
          conversation_name: editingConversationName,
        }),
      });
      if (!resp.ok) {
        setConversationHistory((prevHistory) =>
          prevHistory.map((conv) =>
            conv.conversation_id === conversationId
              ? { ...conv, conversation_name: oldName }
              : conv
          )
        );
        throw new Error(`HTTP error! status: ${resp.status}`);
      }
      //   const data = await resp.json();
    } catch (error) {
      console.error("Error updating conversation name:", error);
    } finally {
      setIsUpdatingName(false);
      setEditingConversationId(null);
      setEditingConversationName("");
    }
  };

  const displayedConversations = () => {
    const filtered = searchTerm
      ? conversationHistory.filter((conv) =>
          conv.conversation_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : conversationHistory;
    const today = getDateString(new Date().toISOString());
    const yesterday = getDateString(
      new Date(today - 24 * 60 * 60 * 1000).toISOString()
    );
    const thirtyDaysAgo = getDateString(
      new Date(today - 30 * 24 * 60 * 60 * 1000).toISOString()
    );
    const groupedConversations = filtered.reduce((acc, conv) => {
      const date = getDateString(conv.conversation_created_at);

      let dateGroup;
      switch (true) {
        case date === today:
          dateGroup = "today";
          break;
        case date === yesterday:
          dateGroup = "yesterday";
          break;
        case date > thirtyDaysAgo:
          dateGroup = "lastMonth";
          break;
        default:
          dateGroup = new Date(conv.conversation_created_at).toLocaleDateString(
            "en-US",
            {
              month: "long",
              year: "numeric",
            }
          );
      }
      if (!acc[dateGroup]) {
        acc[dateGroup] = [];
      }
      acc[dateGroup].push(conv);
      return acc;
    }, {});

    return groupedConversations;
  };

  const myConversations = displayedConversations();

  const proceedWithDelete = async () => {
    if (deletingConversationId) {
      console.time("proceedWithDelete");
      try {
        setIsDeletingConversation(true);
        console.time("deleteRequest");
        const resp = await fetch(
          `${API_HOSTNAME}/chat/delete_chat?conversation_id=${deletingConversationId}`,
          {
            method: "DELETE",
            headers: getHeaders(),
            credentials: "include",
          }
        );
        console.timeEnd("deleteRequest");

        if (!resp.ok) {
          throw new Error(`HTTP error! status: ${resp.status}`);
        }

        console.time("updateState");
        setConversationHistory((prevHistory) =>
          prevHistory.filter(
            (conv) => conv.conversation_id !== deletingConversationId
          )
        );
        if (currentConversationId === deletingConversationId) {
          setCurrentConversationId(null);
        }
        console.timeEnd("updateState");
      } catch (error) {
        console.error("Error deleting conversation:", error);
        fetchUserConversations();
      } finally {
        setIsDeletingConversation(false);
      }
      setDeletingConversationId(null);
      console.timeEnd("proceedWithDelete");
    }
  };

  const cancelEditing = () => {
    setEditingConversationId(null);
    setEditingConversationName("");
  };

  const cancelDelete = () => {
    setDeletingConversationId(null);
  };

  const startEditingConversation = (conversationId, currentName) => {
    setEditingConversationId(conversationId);
    setEditingConversationName(currentName);
  };

  const viewConversation = async (conversation_id) => {
    setCurrentConversationId(conversation_id);
  };

  const chatStartNew = async () => {
    try {
      setIsCreatingNewChat(true);
      const resp = await fetch(`${API_HOSTNAME}/chat/start_new`, {
        method: "POST",
        headers: getHeaders(),
        credentials: "include",
      });
      if (!resp.ok) {
        throw new Error(`HTTP error! status: ${resp.status}`);
      }
      const data = await resp.json();

      setConversationHistory((conversationHistory) => [
        data,
        ...conversationHistory,
      ]);
      setCurrentConversationId(data.conversation_id);
    } catch (error) {
      console.error("Error starting chat:", error);
    } finally {
      setIsCreatingNewChat(false);
    }
  };

  const newConversation = () => {
    chatStartNew();
  };

  useEffect(() => {
    fetchUserConversations();
  }, []);

  return (
    <CPS.Sidebar>
      <CPS.SearchBar
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <CPS.HistoryContainer>
        {isFetchingConversations && !isDeletingConversation ? (
          <CPS.LoadingContainer>
            <CPS.LoadingWheel />
          </CPS.LoadingContainer>
        ) : (
          Object.keys(myConversations).map((dateGroup) => (
            <CPS.DateHeader key={dateGroup}>
              {" "}
              {DateGroups[dateGroup] || dateGroup}
              {myConversations[dateGroup]?.map((conversation) => (
                <CPS.HistorySection key={conversation.conversation_id}>
                  {editingConversationId === conversation.conversation_id ? (
                    <CPS.EditNameForm
                      onSubmit={(e) => {
                        e.preventDefault();
                        saveConversationName(conversation.conversation_id);
                      }}
                    >
                      <CPS.EditNameInput
                        value={editingConversationName}
                        onChange={(e) =>
                          setEditingConversationName(e.target.value)
                        }
                        autoFocus
                      />
                      <CPS.EditNameButton
                        type="button"
                        onClick={cancelEditing}
                        disabled={isUpdatingName}
                        title="Cancel"
                      >
                        <FaTimes />
                      </CPS.EditNameButton>
                      <CPS.EditNameButton
                        type="submit"
                        disabled={isUpdatingName}
                        title="Save"
                      >
                        {isUpdatingName ? (
                          <FaSpinner className="spinner" />
                        ) : (
                          <FaCheck />
                        )}
                      </CPS.EditNameButton>
                    </CPS.EditNameForm>
                  ) : (
                    <CPS.HistoryItem
                      onClick={() =>
                        viewConversation(conversation.conversation_id)
                      }
                      isSelected={
                        currentConversationId === conversation.conversation_id
                      }
                    >
                      <CPS.ChatIcon>
                        <Chat style={{ width: ".7em", height: ".7em" }} />
                      </CPS.ChatIcon>
                      <CPS.ConversationName
                        data-full-text={conversation.conversation_name}
                      >
                        {conversation.conversation_name}
                      </CPS.ConversationName>
                      <CPS.EditButton
                        onClick={(e) => {
                          e.stopPropagation();
                          startEditingConversation(
                            conversation.conversation_id,
                            conversation.conversation_name
                          );
                        }}
                      >
                        <Edit style={{ width: ".7em", height: ".7em" }} />
                      </CPS.EditButton>
                      <CPS.DeleteButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeletingConversationId(
                            conversation.conversation_id
                          );
                        }}
                      >
                        <Delete style={{ width: ".7em", height: ".7em" }} />
                      </CPS.DeleteButton>
                    </CPS.HistoryItem>
                  )}
                </CPS.HistorySection>
              ))}
            </CPS.DateHeader>
          ))
        )}
      </CPS.HistoryContainer>
      <CPS.NewChatButton onClick={newConversation}>New Chat</CPS.NewChatButton>
      {deletingConversationId && (
        <CPS.DeleteConfirmationPopup>
          <p>Are you sure you want to delete this conversation?</p>
          {isDeletingConversation ? (
            <CPS.CenteredContainer>
              <CPS.LoadingWheel />
            </CPS.CenteredContainer>
          ) : (
            <CPS.CenteredContainer>
              <CPS.DeleteConfirmButton onClick={proceedWithDelete}>
                Yes, delete
              </CPS.DeleteConfirmButton>
              <CPS.DeleteCancelButton onClick={cancelDelete}>
                Cancel
              </CPS.DeleteCancelButton>
            </CPS.CenteredContainer>
          )}
        </CPS.DeleteConfirmationPopup>
      )}
    </CPS.Sidebar>
  );
};

export default ChatSidebar;
