import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as CPS from "./ChatPage.styles";
import FiltersPopup from "./ChatComponents/FiltersPopup";
import ChatSidebar from "./ChatComponents/ChatSidebar";
import ChatContainer from "./ChatComponents/ChatContainer";
import { ChatProvider } from "./ChatContext/ChatContext";

export const ChatPage = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({
    countries: [],
    minerals: [],
    projectStages: [],
    startDate: null,
    endDate: null,
  });

  const handleApplyFilters = (filters) => {
    setSelectedFilters(filters);
  };

  return (
    <ChatProvider>
      <CPS.ChatPageContainer>
        <ChatSidebar />
        <ChatContainer />
        <FiltersPopup
          open={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          onApplyFilters={handleApplyFilters}
        />
      </CPS.ChatPageContainer>
    </ChatProvider>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChatPage);
