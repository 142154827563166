/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-else-if */

import { CompareOutlined } from "@material-ui/icons";
import { getFirebase } from "react-redux-firebase";
import { push } from "react-router-redux";
import { CompanyMatchCard } from "../../../components/CompanyMatchCard";
import axios from "axios";
import { searchCLient } from "../../../configure/constants";
import { elements } from "../../../configure/constants";
import { browserHistory } from "react-router";
import { ProjectModel } from "../../../models";
import { parseJwt } from "../../../configure/helpers";
import firebase from "../../../services/firebase";
import { Query } from "../../../configure/Query";
import _ from "lodash";

import { toast } from "react-toastify";
window.addEventListener("beforeunload", function (event) {
  // Check if user is authenticated and has a token
  const token = localStorage.getItem("accessToken");
  if (token) {
    logOut();
  }
});

export const apiRequest = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
});
apiRequest.interceptors.request.use(
  (config) => {
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json;charset=utf-8";
    }

    const token = localStorage.getItem("accessToken");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      return config;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

apiRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error, dispatch) {
    const originalRequest = error.config;
    console.log(error?.response?.status);

    if (error?.response?.status === 401 || error?.response?.status === 403) {
      localStorage.clear();
      // eslint-disable-next-line no-undef
      let webAuth = new auth0.WebAuth({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientID: process.env.REACT_APP_CLIENT_ID,
      });
      webAuth.logout({
        returnTo: window.location.origin,
        client_id: process.env.REACT_APP_CLIENT_ID,
      });
      localStorage.clear();
      // window.location.reload();
      // dispatch({ type: "LOGOUT_REQUEST" });
      // localStorage.removeItem("accessToken");
    }
    return Promise.reject(error);
  }
);

const logi = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_REQUEST" });
  };
};
export const userLogin = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    // make async call to firebase

    dispatch({ type: "LOGIN_REQUEST" });

    const { email, password } = payload;

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .catch(function (error) {
            // Handle error
          });
        const accessToken = await (
          await apiRequest.post("/login", {
            idToken,
            name: userCredential.user.displayName || userCredential.user.email,
          })
        ).data;
        localStorage.setItem("accessToken", accessToken.access_token);

        let ref = await (
          await apiRequest.get(`users/${accessToken.user.id}`)
        ).data.data;

        if (!ref) {
        } else {
          let user = ref;
          if (user.current_company_id) {
            let companyData = await fetchcompanyDetail(user.current_company_id);
            user.companyData = companyData;
          }
          if (user && user.profile_image_id) {
            const profileUrl = await apiRequest
              .get(`/files/${user.profile_image_id}`)
              .catch(function (err) {});
            user.profileUrl = profileUrl.data.data.url;
          }
          dispatch({ type: "LOGIN_SUCCESS", user });
        }
      })
      .catch((err) => {
        let message = err.message;
        dispatch({ type: "LOGIN_FAILURE", message });
      });
  };
};

export const passwordReset = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    dispatch({ type: "SEND_PASSWORD_RESET_REQUEST" });
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    const { email } = payload;
    const his = payload.history;
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({ type: "SENT_PASSWORD_RESET", payload: true });
        his.push({
          pathname: "/browse",
        });
      })
      .catch((error) => {
        var errorCode = error.code;
        var message = error.message;
        // ..
        dispatch({ type: "SENT_PASSWORD_FAILURE", message });
      });
  };
};

export const resolveAuthSession = () => {
  return (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    console.log("happening");
    firebase
      .auth()
      .getRedirectResult()
      .then(async (userCredential) => {
        let user_id = userCredential.user.uid;

        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .catch(function (error) {
            // Handle error
          });

        const {
          firebase: { sign_in_attributes },
        } = parseJwt(idToken);

        const email =
          sign_in_attributes[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
          ];
        const name = sign_in_attributes["http://schemas.auth0.com/nickname"];

        const accessToken = await (
          await apiRequest.post("/login", { idToken, name: name || email })
        ).data;

        localStorage.setItem("accessToken", accessToken.access_token);

        let ref = await (
          await apiRequest.get(`users/${accessToken.user.id}`)
        ).data.data;

        if (!ref) {
        } else {
          let user = ref;
          if (user.current_company_id) {
            let companyData = await fetchcompanyDetail(user.current_company_id);
            user.companyData = companyData;
          }
          if (user && user.profile_image_id) {
            const profileUrl = await apiRequest
              .get(`/files/${user.profile_image_id}`)
              .catch(function (err) {});
            user.profileUrl = profileUrl.data.data.url;
          }
          dispatch({ type: "SIGNUP_SUCCESS", user });
        }
        const {
          authReducer: { signUp },
        } = getState();

        const { password, company, auth_type, interestMinerals, ...que } =
          signUp;

        que.interestMinerals = interestMinerals.map((a) => a.name);
        que.id = user_id;
        que.displayName = name;
        que.email = email;
      })
      .catch((err) => {
        let message = err.message;

        dispatch({ type: "LOGIN_FAILURE", message });
      });
  };
};

export const loginWithNLPortal = ({ history }) => {
  return (dispatch, getState, { getfirebase }) => {
    // make async call to firebase

    dispatch({ type: "LOGIN_REQUEST" });

    let provider = new firebase.auth.SAMLAuthProvider(
      "saml.prospector-central"
    );

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (userCredential) => {
        let user_id = userCredential.user.uid;

        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .catch(function (error) {
            // Handle error
          });

        const {
          firebase: { sign_in_attributes },
        } = parseJwt(idToken);

        const email =
          sign_in_attributes[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
          ];
        const name =
          sign_in_attributes[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
          ] || sign_in_attributes["http://schemas.auth0.com/nickname"];

        const accessToken = await (
          await apiRequest
            .post("/login", { idToken, name: name || email })
            .catch((e) => {
              console.log("logi", e);
              //  dispatch(logOut());
            })
        ).data;

        localStorage.setItem("accessToken", accessToken.access_token);

        let ref = await (
          await apiRequest.get(`users/${accessToken.user.id}`)
        ).data.data;

        if (!ref) {
        } else {
          let user = ref;
          if (user.current_company_id) {
            let companyData = await fetchcompanyDetail(user.current_company_id);
            user.companyData = companyData;
          }
          if (user && user.profile_image_id) {
            const profileUrl = await apiRequest
              .get(`/files/${user.profile_image_id}`)
              .catch(function (err) {});
            user.profileUrl = profileUrl.data.data.url;
          }
          dispatch({ type: "SIGNUP_SUCCESS", user });
        }
        const {
          authReducer: { signUp },
        } = getState();

        const { password, company, auth_type, interestMinerals, ...que } =
          signUp;

        que.interestMinerals = interestMinerals.map((a) => a.name);
        que.id = user_id;
        que.displayName = name;
        que.email = email;
      })
      .catch((err) => {
        let message = err.message;
        if (history) {
          history.push("/browse");
        }
        dispatch({ type: "LOGIN_FAILURE", message });
      });
  };
};

export const loginWithNLProjectPortal = (payload) => {
  const his = payload.history;

  return (dispatch, getState, { getfirebase }) => {
    let provider = new firebase.auth.SAMLAuthProvider(
      "saml.prospector-central"
    );

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (userCredential) => {
        let user_id = userCredential.user.uid;

        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .catch(function (error) {
            // Handle error
          });

        const {
          firebase: { sign_in_attributes },
        } = parseJwt(idToken);

        const email =
          sign_in_attributes[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
          ];
        const name =
          sign_in_attributes[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
          ] || sign_in_attributes["http://schemas.auth0.com/nickname"];

        const accessToken = await (
          await apiRequest.post("/login", { idToken, name: name || email })
        ).data;

        localStorage.setItem("accessToken", accessToken.access_token);

        let ref = await (
          await apiRequest.get(`users/${accessToken.user.id}`)
        ).data.data;

        if (!ref) {
        } else {
          let user = ref;
          if (user.current_company_id) {
            let companyData = await fetchcompanyDetail(user.current_company_id);
            user.companyData = companyData;
          }
          if (user && user.profile_image_id) {
            const profileUrl = await apiRequest
              .get(`/files/${user.profile_image_id}`)
              .catch(function (err) {});
            user.profileUrl = profileUrl.data.data.url;
          }
          dispatch({ type: "SIGNUP_SUCCESS", user });
          console.log(payload.pth);
          // window.open(payload.path, "_blank");
        }
        const {
          authReducer: { signUp },
        } = getState();

        const { password, company, auth_type, interestMinerals, ...que } =
          signUp;

        que.interestMinerals = interestMinerals.map((a) => a.name);
        que.id = user_id;
        que.displayName = name;
        que.email = email;
      })
      .catch((err) => {
        let message = err.message;

        // dispatch({ type: "LOGIN_FAILURE", message });
      });
  };
};

export const linkedinLogin = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    // make async call to firebase

    dispatch({ type: "LOGIN_REQUEST" });
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    let provider = new firebase.auth.OAuthProvider("linkedin.com");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (userCredential) => {
        let user_id = userCredential.user.uid;
        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .catch(function (error) {
            // Handle error
          });
        const accessToken = await (
          await apiRequest.post("/login", {
            idToken,
            name: userCredential.user.displayName || userCredential.user.email,
          })
        ).data;
        localStorage.setItem("accessToken", accessToken.access_token);

        let ref = await (
          await apiRequest.get(`users/${accessToken.user.id}`)
        ).data.data;

        if (!ref) {
        } else {
          let user = ref;
          if (user.current_company_id) {
            let companyData = await fetchcompanyDetail(user.current_company_id);
            user.companyData = companyData;
          }
          if (user && user.profile_image_id) {
            const profileUrl = await apiRequest
              .get(`/files/${user.profile_image_id}`)
              .catch(function (err) {});
            user.profileUrl = profileUrl.data.data.url;
          }
          dispatch({ type: "SIGNUP_SUCCESS", user });
        }
        const {
          authReducer: { signUp },
        } = getState();
        const { password, company, auth_type, interestMinerals, ...que } =
          signUp;
        que.interestMinerals = interestMinerals.map((a) => a.name);
        que.id = user_id;
        que.displayName = userCredential.user.displayName;
        que.email = userCredential.user.email;
        que.photoURL = userCredential.user.photoURL;
        que.lastName = userCredential.user.displayName.split(" ")[0];
        que.firstName = userCredential.user.displayName
          .split(" ")
          .splice(-1)[0];

        // const greet = async () => {
        //   await firestore.collection('customers').doc(user_id).set(que).then(async () => {
        //     let ref = await firestore.collection('customers').doc(user_id).get()
        //     if (!ref.exists) {
        //     } else {
        //       let user = ref.data()
        //       dispatch({ type: 'SIGNUP_SUCCESS', user })
        //     }
        //   });

        // };

        // setTimeout(greet, 5000);
      })
      .catch((err) => {
        let message = err.message;
        dispatch({ type: "LOGIN_FAILURE", message });
      });
  };
};
export const getUserFollowsList = () => {
  return async (dispatch, getState, { getfirebase }) => {
    const auth = getState().authReducer.activeUser;
    let me = auth.id;

    const data = await apiRequest
      .get(`/users/${me}/follows-list`)
      .catch(function (error) {});
    if (data) {
      dispatch({ type: "SET_USER_FOLLOW_LIST", payload: data.data.data });
    }
  };
};
export const appleLogin = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    // make async call to firebase

    dispatch({ type: "LOGIN_REQUEST" });
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    let provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (userCredential) => {
        let user_id = userCredential.user.uid;
        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .catch(function (error) {
            // Handle error
          });
        const accessToken = await (
          await apiRequest.post("/login", {
            idToken,
            name: userCredential.user.displayName || userCredential.user.email,
          })
        ).data;
        localStorage.setItem("accessToken", accessToken.access_token);

        let ref = await (
          await apiRequest.get(`users/${accessToken.user.id}`)
        ).data.data;

        if (!ref) {
        } else {
          let user = ref;
          if (user.current_company_id) {
            let companyData = await fetchcompanyDetail(user.current_company_id);
            user.companyData = companyData;
          }
          if (user && user.profile_image_id) {
            const profileUrl = await apiRequest
              .get(`/files/${user.profile_image_id}`)
              .catch(function (err) {});
            user.profileUrl = profileUrl.data.data.url;
          }
          dispatch({ type: "SIGNUP_SUCCESS", user });
        }
        const {
          authReducer: { signUp },
        } = getState();
        const { password, company, auth_type, interestMinerals, ...que } =
          signUp;
        que.interestMinerals = interestMinerals.map((a) => a.name);
        que.id = user_id;
        que.displayName = userCredential.user.displayName;
        que.email = userCredential.user.email;
        que.photoURL = userCredential.user.photoURL;
        que.lastName = userCredential.user.displayName.split(" ")[0];
        que.firstName = userCredential.user.displayName
          .split(" ")
          .splice(-1)[0];

        // const greet = async () => {
        //   await firestore.collection('customers').doc(user_id).set(que).then(async () => {
        //     let ref = await firestore.collection('customers').doc(user_id).get()
        //     if (!ref.exists) {
        //     } else {
        //       let user = ref.data()
        //       dispatch({ type: 'SIGNUP_SUCCESS', user })
        //     }
        //   });

        // };

        // setTimeout(greet, 5000);
      })
      .catch((err) => {
        let message = err.message;
        dispatch({ type: "LOGIN_FAILURE", message });
      });
  };
};

export const userSignup = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    // make async call to firebase
    const {
      authReducer: { signUp },
    } = getState();
    const { password, company, auth_type, interestMinerals, ...que } = signUp;
    que.interestMinerals = interestMinerals.map((a) => a.name);
    dispatch({ type: "SIGNUP_REQUEST" });
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(que.email, payload)
      .then(async (userCredential) => {
        let user_id = userCredential.user.uid;
        // sendemailverification
        userCredential.user.sendEmailVerification();
        que.id = user_id;

        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .catch(function (error) {
            // Handle error
          });
        const accessToken = await (
          await apiRequest.post("/login", {
            idToken,
            name: userCredential.user.displayName || userCredential.user.email,
          })
        ).data;
        localStorage.setItem("accessToken", accessToken.access_token);

        let ref = await (
          await apiRequest.get(`users/${accessToken.user.id}`)
        ).data.data;

        if (!ref) {
        } else {
          let user = ref;
          if (user.current_company_id) {
            let companyData = await fetchcompanyDetail(user.current_company_id);
            user.companyData = companyData;
          }
          if (user && user.profile_image_id) {
            const profileUrl = await apiRequest
              .get(`/files/${user.profile_image_id}`)
              .catch(function (err) {});
            user.profileUrl = profileUrl.data.data.url;
          }
          dispatch({ type: "SIGNUP_SUCCESS", user });
        }
      })
      .catch((err) => {
        let message = err.message;
        dispatch({ type: "SIGNUP_FAILURE", message });
      });
  };
};
export const newuserSignup = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    // make async call to firebase

    const { password, company, ...que } = payload;

    dispatch({ type: "SIGNUP_REQUEST" });
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(que.email, payload)
      .then(async (userCredential) => {
        let user_id = userCredential.user.uid;
        // sendemailverification
        userCredential.user.sendEmailVerification();
        que.id = user_id;

        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .catch(function (error) {
            // Handle error
          });
        const accessToken = await (
          await apiRequest.post("/login", {
            idToken,
            name: userCredential.user.displayName || userCredential.user.email,
          })
        ).data;

        localStorage.setItem("accessToken", accessToken.access_token);

        let ref = await (
          await apiRequest.get(`users/${accessToken.user.id}`)
        ).data.data;

        if (!ref) {
        } else {
          let user = ref;
          if (user.current_company_id) {
            let companyData = await fetchcompanyDetail(user.current_company_id);
            user.companyData = companyData;
          }
          if (user && user.profile_image_id) {
            const profileUrl = await apiRequest
              .get(`/files/${user.profile_image_id}`)
              .catch(function (err) {});
            user.profileUrl = profileUrl.data.data.url;
          }
          dispatch({ type: "SIGNUP_SUCCESS", user });
        }
      })
      .catch((err) => {
        let message = err.message;
        dispatch({ type: "SIGNUP_FAILURE", message });
      });
  };
};
export const selectList = (payload) => ({
  type: "SELECT_LIST",
  payload,
});

export const signupType = (payload) => ({
  type: "SIGNUP_TYPE",
  payload,
});

export const updateSelectedChat = (payload) => ({
  type: "SELECT_CHAT",
  payload,
});
export const updateSignUpDetails = (payload) => ({
  type: "UPDATE_SIGNUP_DETAILS",
  payload,
});
export const createChat = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();

    const callableReturnMessage = firebase
      .functions()
      .httpsCallable("createChat");

    callableReturnMessage(payload)
      .then((result) => {
        dispatch(updateSelectedChat(result.data));
        dispatch({ type: "SET_CHAT_SECTION", payload: "chat_section" });
      })
      .catch((error) => {});
  };
};

export const sendMessage = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    let date = Date.now();
    firestore
      .collection("chats")
      .doc(payload.chatId)
      .collection("messages")
      .add({
        timestamp: firebase.firestore.Timestamp.fromDate(new Date(date)),
        ...payload,
      })
      .then(async (userCredential) => {})
      .catch((err) => {
        let message = err.message;
        dispatch({ type: "SEND_MESSAGE_FAILURE", message });
      });
  };
};
export const selectTags = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    const selected = [...getState().authReducer.selectedTags];
    let index = selected.findIndex((item) => item.id === payload.id);
    if (index !== -1) {
      selected.splice(index, 1);
    } else {
      selected.push(payload);
    }
    dispatch({ type: "SET_SELECTED_TAGS", payload: selected });
  };
};

export const logOut = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    console.log("callled");

    // make async call to firebase
    // eslint-disable-next-line no-undef
    let webAuth = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_CLIENT_ID,
    });
    webAuth.logout({
      returnTo: `${window.location.origin}/#/auth/sign-in`,
      client_id: process.env.REACT_APP_CLIENT_ID,
    });
    dispatch({ type: "LOGOUT_REQUEST" });
    localStorage.clear();
    // localStorage.removeItem("accessToken");
  };
};

export const getCompanies = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    const auth = getState().firebase.auth;
    let me = auth.uid;

    const prospects = await apiRequest
      .get("/me/prospects")
      .catch(function (error) {
        // do something
        dispatch({ type: "SET_COMPANIES", payload: [] });
      });
    const data = prospects ? prospects.data.data : [];

    let companies = await Promise.all(
      data.map(async (prospect) => {
        if (prospect.current_company_id) {
          // let companyData = await fetchcompanyDetail(prospect.current_company_id)
          // prospect.companyData = companyData
          //

          prospect.companyData =
            prospect.companies &&
            prospect.companies.find(
              (item) => item.id === prospect.current_company_id
            )
              ? prospect.companies.find(
                  (item) => item.id === prospect.current_company_id
                )
              : await fetchcompanyDetail(prospect.current_company_id);
        }
        return prospect;
      })
    );

    dispatch({ type: "SET_COMPANIES", payload: companies });
  };
};

export const getLists = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const prospects = await apiRequest
      .get("/me/private-lists")
      .catch(function (error) {
        // do something
        dispatch({ type: "SET_LISTS", payload: [] });
      });
    const data = prospects ? prospects.data.data : [];
    getTags();
    dispatch({ type: "SET_LISTS", payload: data });
  };
};
export const getTags = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const prospects = await apiRequest.get("/me/tags").catch(function (error) {
      // do something
      dispatch({ type: "SET_TAGS", payload: [] });
    });
    const data = prospects ? prospects.data.data : [];
    dispatch({ type: "SET_TAGS", payload: data });
  };
};

export const getProspects = () => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    const auth = getState().authReducer.activeUser;
    let me = auth.id;

    const prospects = await apiRequest
      .get(`/users/${me}/follows`)
      .catch(function (error) {
        // do something
        dispatch({ type: "SET_PROSPECTS", payload: [] });
      });
    const data = prospects ? prospects.data.data : [];

    let companies = await Promise.all(
      data.map(async (prospect) => {
        if (prospect.current_company_id) {
          let companyData = await fetchcompanyDetail(
            prospect.current_company_id
          );
          prospect.companyData = companyData;
        }
        return prospect;
      })
    );
    dispatch({ type: "SET_PROSPECTS", payload: companies });
  };
};

export const matchUsers = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const data = await apiRequest
      .post("/follows", { followable_id: payload })
      .catch(function (error) {});
    if (data) {
      // dispatch(getProspects())
      // dispatch(filterCompaniesStaging())
      dispatch(getUserFollowsList());
      // window.location.reload()
    }
    // const firebase = getFirebase();

    // const callableReturnMessage = firebase.functions().httpsCallable('matchUsers');
    // callableReturnMessage(payload).then((result) => {

    // }).catch((error) => {

    // });
  };
};

export const searchCompanies = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const { me } = payload;
    const firebase = getFirebase();
    const db = firebase.firestore();
    const companiesData = await db.collection("companies").get();
    const companies = companiesData.docs.map((snap) => ({
      id: snap.id,
      ...snap.data(),
    }));

    const outgoingMatchRequests = db
      .collection("matches")
      .where("requestedBy", "==", me)

      .get();

    const pendingMatchRequests = db
      .collection("matches")
      .where("targetCustomer", "==", me)

      .get();

    const [outgoingMatchesSnap, pendingMatchesSnap] = await Promise.all([
      outgoingMatchRequests,
      pendingMatchRequests,
    ]);

    // const incomingMatchCustomerIds = incomingMatchesSnap.docs.map(snap => snap.data().targetCustomer);
    const outgoingMatchCustomerIds = outgoingMatchesSnap.docs.map((snap) => ({
      id: snap.data().targetCustomer,
      status: snap.data().status,
      companyId: snap.data().targetUserData.companyId,
    }));
    const pendingMatchCustomerIds = pendingMatchesSnap.docs.map((snap) => ({
      id: snap.data().targetCustomer,
      status: snap.data().status,
      companyId: snap.data().targetUserData.companyId,
    }));

    const matchedUsers = [
      ...new Set([...outgoingMatchCustomerIds, ...pendingMatchCustomerIds]),
    ];

    let data = await Promise.all(
      companies.map(async (company) => {
        // let stock = await db.collection('companies').doc(company.id).collection('stockHistory').get()
        let history = await apiRequest.get(
          `/stock-history?identifier=${company.yahoo_ticker}`
        ).data;
        // const history = stock.docs.map(snap => (
        //   {
        //     id: snap.id,
        //     ...snap.data()
        //   }))

        const median = history.length
          ? history.reduce((a, b) => a + (b.high + b.low) / 2, 0) /
            history.data.length
          : "none";

        company.stockHistory = history;
        company.median = median;
        company.status = matchedUsers.find(
          (item) => item.companyId === company.id
        )
          ? matchedUsers.find((item) => item.companyId === company.id).status
          : "Not Connected";

        return company;
      })
    );

    dispatch({
      type: "SET_PROJECT_COMPANIES",
      payload: data.filter(
        (item) => item.stockHistory.length && item.nlProjectData
      ),
    });
  };
};

export const deleteMatches = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    let me = payload;
    const firebase = getFirebase();
    const db = firebase.firestore();
    db.collection("matches")
      .where("requestedBy", "==", me)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.delete();
        });
      });
  };
};
export const getSocials = (payload) => {
  return (dispatch, getState, { getfirebase }) => {
    let me = payload;
    const firebase = getFirebase();
    const db = firebase.firestore();
    db.collection("matches")
      .where("requestedBy", "==", me)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.delete();
        });
      });
  };
};
export const getCommodityData = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    //  const  {mineral,interval,range} = payload
    const performanceData = await apiRequest
      .get(
        `/stock-history?identifier=${payload.identifier}&range=${payload.range}&interval=${payload.interval}`
      )
      .catch(function (error) {
        // Handle error
      });

    dispatch({
      type: "SET_COMMODITY_STOCK_DATA",
      payload: performanceData ? performanceData.data : null,
    });
  };
};
export const getCommpanyStockData = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    console.log(payload.range[1]);
    const performanceData = await apiRequest
      .get(
        `companies/${payload.id}/stock-price?range=1&range_period=${payload.range[1]}&interval=${payload.interval[1]}`
      )
      .catch(function (error) {
        // Handle error
      });

    dispatch({
      type: "SET_COMMODITY_STOCK_DATA",
      payload: performanceData ? performanceData.data : null,
    });
  };
};
export const getProjectNews = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    //  const  {mineral,interval,range} = payload
    await apiRequest
      .get(`/nlsearch?datasource=news&searchTerm=${payload.searchTerm || ""}`)
      .then((result) => {
        dispatch({
          type: "SET_PROJECT_NEWS",
          payload: {
            data: result.data.data || [],
            next: result.data.meta.nextRecordAvailable,
            charts: result.data.chartData,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_PROJECT_NEWS",
          payload: { data: [], next: 0, charts: null },
        });
      });
  };
};
export const getProjectSocial = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    await apiRequest
      .get(`/nlsearch?datasource=social&searchTerm=${payload.searchTerm || ""}`)
      .then((result) => {
        dispatch({
          type: "SET_PROJECT_SOCIAL",
          payload: {
            data: result.data.data || [],
            next: result.data.meta.nextRecordAvailable,
            charts: result.data.chartData,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_PROJECT_SOCIAL",
          payload: { data: [], next: 0, charts: null },
        });
      });
  };
};
export const getProjectReports = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    // const firebase = getFirebase();
    dispatch({ type: "SET_PROJECT_REPORTS", payload: [] });
    dispatch({ type: "SET_PROJECT_REPORTS_LOADING", payload: true });
    // await apiRequest.get(`/nlsearch?datasourse=reports&searchTerm=${payload.searchTerm}`).then((result) => {
    //   dispatch({ type: 'SET_PROJECT_REPORTS', payload: { data: result.data.data || [], next: result.data.meta.nextRecordAvailable } })
    // }).catch((error) => {
    //   // dispatch({type:'SET_COMPANIES',payload:[]})
    // });
    const reportsData = await apiRequest
      .get(`/projects/${payload}/documents`)
      .catch(function (error) {});
    const reports = reportsData ? reportsData.data.data : [];
    dispatch({
      type: "SET_PROJECT_REPORTS",
      payload: _.orderBy(
        reports.filter((item) => item.document_type === "Technical Report"),
        "publication_date",
        "asc"
      ),
    });
    dispatch({ type: "SET_PROJECT_REPORTS_LOADING", payload: false });
  };
};

/* projects */
export const filterProjects = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    dispatch({ type: "SET_PROJECTS_LOADING", payload: true });
    const {
      authReducer: {
        projects_search_filters,
        project_sort_filter,
        mapBounds,
        projects_page,
        project_start_after,
      },
    } = getState();
    const auth = getState().firebase.auth;

    let me = auth.uid;
    let queryString = project_sort_filter.name
      ? `&sort=${project_sort_filter.name}:${project_sort_filter.type}`
      : "";
    let filter = {
      collection: "projects",
      page: projects_page,
      hitsPerPage: 20,
      boundingBox: mapBounds,
    };
    const {
      mineral,
      primary_metal,
      countries,
      searchTerm,
      stage,
      exchange,
      resourceType,
      averageGrade_min,
      averageGrade_max,
      oreTonnage_min,
      oreTonnage_max,
      containedMetal_min,
      containedMetal_max,
      resource_measurements,
    } = projects_search_filters;
    if (mapBounds && !countries) {
      // queryString = `${queryString}&boundingBox[]=${mapBounds[0]}&boundingBox[]=${mapBounds[1]}&boundingBox[]=${mapBounds[2]}&boundingBox[]=${mapBounds[3]}`
    }
    if (searchTerm) {
      queryString = `${queryString}&searchTerm=${searchTerm}`;
    }

    if (exchange) {
      let list = exchange.toString();

      queryString = `${queryString}&exchange=${list}`;
    }
    if (mineral && mineral.length) {
      // filter.minerals = [mineral]

      let list = mineral.toString();

      queryString = `${queryString}&minerals=${list}`;
    }
    if (primary_metal) {
      // filter.minerals = [mineral]

      let list = primary_metal.toString();

      queryString = `${queryString}&primary_metal=${list}`;
    }
    if (countries && countries.length) {
      // filter.countries = [countries]
      // filter.region = countries
      let list = countries.toString();

      queryString = `${queryString}&region=${list}`;
    }
    if (stage) {
      console.log(stage);
      queryString = `${queryString}&stages=${stage.map((item) =>
        item.replace("&", "%26")
      )}`;
    }
    if (resourceType) {
      // filter.mineralEstimate = [resourceType]

      queryString = `${queryString}&mineral_estimate=${resourceType}`;
    }
    // if (
    //   mineral &&
    //   averageGrade_max > 0 &&
    //   averageGrade_min !== averageGrade_max
    // ) {
    //   filter["minerals.Grade.depositAggregate"] = [
    //     averageGrade_min,
    //     averageGrade_max,
    //   ];
    // }

    // if (resource_measurements) {
    //   queryString = `${queryString}&${resource_measurements}`;
    // }

    // //  if(containedMetal_max > 0 && containedMetal_max !== containedMetal_min){
    // //   filter.containedMetal = [containedMetal_min,containedMetal_max]
    // // }
    // if (mineral && oreTonnage_max > 0 && oreTonnage_min !== oreTonnage_max) {
    //   filter["minerals.Tonnage.depositAggregate"] = [
    //     oreTonnage_min,
    //     oreTonnage_max,
    //   ];
    // }
    // if (mineral && oreTonnage_max > 0 && oreTonnage_min !== oreTonnage_max) {
    //   filter["minerals.Tonnage.depositAggregate"] = [
    //     oreTonnage_min,
    //     oreTonnage_max,
    //   ];
    // }

    const mapData =
      project_start_after === 30000
        ? await apiRequest
            .get(`/search?collection=project_locations${queryString}`)
            .catch(function (err) {})
        : await apiRequest
            .get(
              `/search?collection=project_locations${queryString}&start_after=${project_start_after}`
            )
            .catch(function (err) {});

    const data =
      project_start_after === 30000
        ? await apiRequest
            .get(
              `/v2/search-projects?${queryString}&shallow=true&page=${
                projects_page < 1 ? 1 : projects_page
              }&page_size=50`
            )
            .catch(function (err) {})
        : await apiRequest
            .get(
              `/v2/search-projects?${queryString}&start_after=${project_start_after}&shallow=true&page=${
                projects_page < 1 ? 1 : projects_page
              }&page_size=50`
            )
            .catch(function (err) {});

    const projects = data ? data.data.data : [];
    let projectData = await Promise.all(
      projects.map(async (project) => {
        let companydata = project.investors
          ? await Promise.all(
              project.investors.map(async (company) => {
                // const history = company.yahoo_ticker ? await apiRequest.get(`/stock-history?identifier=${company.yahoo_ticker}`).catch(function (err) {
                //
                // }) : []

                return company;
              })
            )
          : [];
        project.investors = companydata;
        return project;
      })
    );
    let pageTo = data
      ? data.data.meta.last_page < data.data.meta.current_page + 1
        ? data.data.meta.current_page
        : data.data.meta.current_page + 1
      : 1;
    dispatch({
      type: "SET_PROJECTS",
      payload: {
        projects: projectData.map((project) => new ProjectModel(project)),
        page: data.data.meta.current_page,
      },
    });
    dispatch({
      type: "SET_MAP_PROJECTS",
      payload: mapData ? mapData.data.data : [],
    });
    dispatch({ type: "SET_PROJECTS_PAGE", payload: pageTo });
    dispatch({
      type: "SET_PROJECTS_PAGE_COUNT",
      payload: data ? data.data.meta.last_page : 1,
    });
    dispatch({
      type: "SET_PROJECTS_LENGTH",
      payload: mapData ? mapData.data.data.length : projectData.length,
    });
    dispatch({ type: "SET_PROJECTS_LOADING", payload: false });

    // const callableReturnMessage = firebase.functions().httpsCallable('search');
    // callableReturnMessage(filter).then((result) => {

    //   // dispatch(searchAlgoliaProjects({ hits: result.data.hits, me }))
    //   // if (result.data.page + 1 < result.data.nbPages) {
    //   //   dispatch({ type: 'SET_PROJECTS_PAGE', payload: result.data.page + 1 })
    //   //   dispatch({ type: 'SET_PROJECTS_LENGTH', payload: result.data.nbHits })
    //   // } else {
    //   //   dispatch({ type: 'SET_PROJECTS_PAGE', payload: 0 })
    //   //   dispatch({ type: 'SET_PROJECTS_LENGTH', payload: result.data.nbHits })

    //   // }
    // }).catch((error) => {
    //   // dispatch({type:'SET_COMPANIES',payload:[]})
    // });
  };
};
export const filterProjectsMap = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    dispatch({ type: "SET_PROJECTS_LOADING", payload: true });
    const {
      authReducer: {
        projects_search_filters,
        project_sort_filter,
        mapBounds,
        projects_page,
        project_start_after,
      },
    } = getState();
    const auth = getState().firebase.auth;

    let me = auth.uid;
    let queryString = project_sort_filter.name
      ? `&sort=${project_sort_filter.name}:${project_sort_filter.type}`
      : "";
    let filter = {
      collection: "projects",
      page: projects_page,
      hitsPerPage: 20,
      boundingBox: mapBounds,
    };
    const {
      mineral,
      countries,
      searchTerm,
      stage,
      exchange,
      resourceType,
      averageGrade_min,
      averageGrade_max,
      oreTonnage_min,
      oreTonnage_max,
      containedMetal_min,
      containedMetal_max,
    } = projects_search_filters;
    if (mapBounds && !countries) {
      // queryString = `${queryString}&boundingBox[]=${mapBounds[0]}&boundingBox[]=${mapBounds[1]}&boundingBox[]=${mapBounds[2]}&boundingBox[]=${mapBounds[3]}`
    }
    if (searchTerm) {
      queryString = `${queryString}&searchTerm=${searchTerm}`;
    }

    if (exchange && exchange.length) {
      let list = exchange.toString();

      queryString = `${queryString}&exchange=${list}`;
    }
    if (mineral && mineral.length) {
      // filter.minerals = [mineral]

      let list = mineral.toString();

      queryString = `${queryString}&minerals=${list}`;
    }
    if (countries && countries.length) {
      // filter.countries = [countries]
      // filter.region = countries
      let list = countries.toString();

      queryString = `${queryString}&region=${list}`;
    }
    if (stage) {
      queryString = `${queryString}&stages=${stage}`;
    }
    if (resourceType) {
      // filter.mineralEstimate = [resourceType]

      queryString = `${queryString}&mineral_estimate=${resourceType}`;
    }
    if (
      mineral &&
      averageGrade_max > 0 &&
      averageGrade_min !== averageGrade_max
    ) {
      filter["minerals.Grade.depositAggregate"] = [
        averageGrade_min,
        averageGrade_max,
      ];
    }
    //  if(containedMetal_max > 0 && containedMetal_max !== containedMetal_min){
    //   filter.containedMetal = [containedMetal_min,containedMetal_max]
    // }
    if (mineral && oreTonnage_max > 0 && oreTonnage_min !== oreTonnage_max) {
      filter["minerals.Tonnage.depositAggregate"] = [
        oreTonnage_min,
        oreTonnage_max,
      ];
    }

    const mapData =
      project_start_after === 30000
        ? await apiRequest
            .get(`/search?collection=project_locations${queryString}`)
            .catch(function (err) {})
        : await apiRequest
            .get(
              `/search?collection=project_locations${queryString}&start_after=${project_start_after}`
            )
            .catch(function (err) {});
    const data =
      project_start_after === 30000
        ? await apiRequest
            .get(
              `/v2/search-projects?${queryString}&shallow=true&page=${
                projects_page < 1 ? 1 : projects_page
              }&page_size=50`
            )
            .catch(function (err) {})
        : await apiRequest
            .get(
              `/v2/search-projects?${queryString}&start_after=${project_start_after}&shallow=true&page=${
                projects_page < 1 ? 1 : projects_page
              }&page_size=50`
            )
            .catch(function (err) {});

    const projects = data ? data.data.data : [];
    let projectData = await Promise.all(
      projects.map(async (project) => {
        let companydata = project.investors
          ? await Promise.all(
              project.investors.map(async (company) => {
                // const history = company.yahoo_ticker ? await apiRequest.get(`/stock-history?identifier=${company.yahoo_ticker}`).catch(function (err) {
                //
                // }) : []

                return company;
              })
            )
          : [];
        project.investors = companydata;
        return project;
      })
    );
    let pageTo = data
      ? data.data.meta.last_page < data.data.meta.current_page + 1
        ? data.data.meta.current_page
        : data.data.meta.current_page + 1
      : 1;
    dispatch({
      type: "SET_PROJECTS",
      payload: {
        projects: projectData.map((project) => new ProjectModel(project)),
        page: data.data.meta.current_page,
      },
    });
    dispatch({
      type: "SET_MAP_PROJECTS",
      payload: mapData ? mapData.data.data : [],
    });
    dispatch({ type: "SET_PROJECTS_PAGE", payload: pageTo });
    dispatch({
      type: "SET_PROJECTS_PAGE_COUNT",
      payload: data ? data.data.meta.last_page : 1,
    });
    dispatch({
      type: "SET_PROJECTS_LENGTH",
      payload: mapData ? mapData.data.data.length : projectData.length,
    });
    dispatch({ type: "SET_PROJECTS_LOADING", payload: false });

    // const callableReturnMessage = firebase.functions().httpsCallable('search');
    // callableReturnMessage(filter).then((result) => {

    //   // dispatch(searchAlgoliaProjects({ hits: result.data.hits, me }))
    //   // if (result.data.page + 1 < result.data.nbPages) {
    //   //   dispatch({ type: 'SET_PROJECTS_PAGE', payload: result.data.page + 1 })
    //   //   dispatch({ type: 'SET_PROJECTS_LENGTH', payload: result.data.nbHits })
    //   // } else {
    //   //   dispatch({ type: 'SET_PROJECTS_PAGE', payload: 0 })
    //   //   dispatch({ type: 'SET_PROJECTS_LENGTH', payload: result.data.nbHits })

    //   // }
    // }).catch((error) => {
    //   // dispatch({type:'SET_COMPANIES',payload:[]})
    // });
  };
};

export const searchAlgoliaProjects = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const me = payload.me;
    const firebase = getFirebase();
    const db = firebase.firestore();

    const companies = payload.hits.map((snap) => ({
      id: snap.objectID,
      ...snap,
    }));
    let matchedUsers = null;

    if (me) {
      const outgoingMatchRequests = await db
        .collection("matches")
        .where("requestedBy", "==", me)

        .get();

      const pendingMatchRequests = await db
        .collection("matches")
        .where("targetCustomer", "==", me)

        .get();

      const [outgoingMatchesSnap, pendingMatchesSnap] = await Promise.all([
        outgoingMatchRequests,
        pendingMatchRequests,
      ]);

      const outgoingMatchCustomerIds = outgoingMatchesSnap.docs.map((snap) => ({
        id: snap.data().targetCustomer,
        status: snap.data().status,
        companyId: snap.data().targetUserData.companyId,
      }));
      const pendingMatchCustomerIds = pendingMatchesSnap.docs.map((snap) => ({
        id: snap.data().targetCustomer,
        status: snap.data().status,
        companyId: snap.data().targetUserData.companyId,
      }));

      matchedUsers = [
        ...new Set([...outgoingMatchCustomerIds, ...pendingMatchCustomerIds]),
      ];
    }

    let data = await Promise.all(
      companies.map(async (company) => {
        if (me) {
          let companyData = await db
            .collection("companies")
            .doc(company.companyData.id)
            .get();
          const projectCompany = { id: companyData.id, ...companyData.data() };
          let stock = await db
            .collection("companies")
            .doc(company.companyData.id)
            .collection("stockHistory")
            .get();
          company.companyData.minerals = projectCompany.minerals;
          company.companyData.projects = projectCompany.projects.map(
            (project) => new ProjectModel(project)
          );
          let history = (
            await apiRequest.get(
              `/stock-history?identifier=${projectCompany.yahooTicker}`
            )
          ).data.data;
          const median =
            history && history.length
              ? history.reduce((a, b) => a + (b.high + b.low) / 2, 0) /
                history.data.length
              : "none";
          company.median = median;
        }

        company.stockHistory = [];
        company.status = me
          ? matchedUsers.find((item) => item.companyId === company.id)
            ? matchedUsers.find((item) => item.companyId === company.id).status
            : "Not Connected"
          : null;

        return company;
      })
    );

    dispatch({
      type: "SET_PROJECTS",
      payload: {
        projects: data.map((project) => new ProjectModel(project)),
        page: data ? data.data.meta.current_page : 0,
      },
    });
  };
};
const fetchProjectDetail = async (payload) => {
  const data = await apiRequest
    .get(`/projects/${payload}`)
    .catch(function (err) {});

  const project = data ? data.data.data : [];
  return project;
};

export const filterProjectsStaging = (payload) => {
  return async (dispatch, getState) => {
    const {
      authReducer: { projects_page, project_start_after },
    } = getState();
    const data = await apiRequest
      .get(
        `/projects?shallow=true&page_size=3000&start_after=${project_start_after}`
      )
      .catch(function (err) {});

    const projects = data ? data.data.data : [];
    let projectData = await Promise.all(
      projects.map(async (project) => {
        let companydata = project.investors
          ? await Promise.all(
              project.investors.map(async (company) => {
                // const history = company.yahoo_ticker ? await apiRequest.get(`/stock-history?identifier=${company.yahoo_ticker}`).catch(function (err) {
                //
                // }) : []
                const history = company.stock_history;
                const median =
                  history && history.length
                    ? history.reduce((a, b) => a + (b.high + b.low) / 2, 0) /
                      history.data.length
                    : "none";
                company.stockHistory =
                  history && history.data ? history.data : [];

                company.median = median;
                return company;
              })
            )
          : [];
        project.investors = companydata;
        return project;
      })
    );

    dispatch({
      type: "SET_PROJECTS",
      payload: {
        projects: projectData.map((project) => new ProjectModel(project)),
        page: data ? data.data.meta.current_page : 0,
      },
    });
    dispatch({
      type: "SET_PROJECTS_PAGE",
      payload: data ? data.data.meta.current_page + 1 : 0,
    });
    dispatch({ type: "SET_PROJECTS_LENGTH", payload: 15 });
  };
};

export const getProjectDetail = (payload) => {
  const his = payload.history;

  return async (dispatch, getState, { getfirebase }) => {
    const auth = getState().firebase.auth;
    dispatch({ type: "SET_LOADING", payload: true });
    let me = auth.uid;
    const firebase = getFirebase();
    const db = firebase.firestore();

    const project = await fetchProjectDetail(payload.id);
    const project_years = await apiRequest
      .get(`/projects/${payload.id}/production-years`)
      .catch((err) => console.log("err"));

    const mrr_years = await apiRequest
      .get(`/projects/${payload.id}/mrr-annual-growth`)
      .catch((err) => console.log("err"));
    if (mrr_years && mrr_years.data.data) {
      project.mrr_years = _.uniq(mrr_years.data.data.map((item) => item.label));
    }

    let companydata = project.investors
      ? await Promise.all(
          project.investors.map(async (company) => {
            const history =
              company.yahoo_ticker && !company.stock_history
                ? (
                    await apiRequest
                      .get(`/stock-history?identifier=${company.yahoo_ticker}`)
                      .catch(function (err) {})
                  )?.data
                : company.stock_history
                ? company.stock_history
                : [];

            const median =
              history && history?.data && history?.data.length
                ? history.data.reduce((a, b) => a + (b.high + b.low) / 2, 0) /
                  history.data.length
                : "none";
            company.stockHistory =
              history && history?.data && history?.data ? history?.data : [];

            company.median = median;
            return company;
          })
        )
      : [];
    project.investors = companydata;
    project.years = project_years?.data?.data;
    dispatch({ type: "SET_PROJECT", payload: new ProjectModel(project) });
    dispatch({ type: "SET_LOADING", payload: false });
    his.push({
      pathname: `/project-detail/${project.id}`,
      state: { ...project },
    });
  };
};
export const searchProjects = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const { me } = payload;
    const firebase = getFirebase();
    const db = firebase.firestore();
    const projects = (await apiRequest.get("/projects")).data.data;
    let matchedUsers = null;
    if (me) {
      const outgoingMatchRequests = db
        .collection("matches")
        .where("requestedBy", "==", me)

        .get();

      const pendingMatchRequests = db
        .collection("matches")
        .where("targetCustomer", "==", me)

        .get();

      const [outgoingMatchesSnap, pendingMatchesSnap] = await Promise.all([
        outgoingMatchRequests,
        pendingMatchRequests,
      ]);

      // const incomingMatchCustomerIds = incomingMatchesSnap.docs.map(snap => snap.data().targetCustomer);
      const outgoingMatchCustomerIds = outgoingMatchesSnap.docs.map((snap) => ({
        id: snap.data().targetCustomer,
        status: snap.data().status,
        companyId: snap.data().targetUserData.companyId,
      }));
      const pendingMatchCustomerIds = pendingMatchesSnap.docs.map((snap) => ({
        id: snap.data().targetCustomer,
        status: snap.data().status,
        companyId: snap.data().targetUserData.companyId,
      }));

      matchedUsers = [
        ...new Set([...outgoingMatchCustomerIds, ...pendingMatchCustomerIds]),
      ];
    }

    let data = await Promise.all(
      projects.map(async (company) => {
        const history = [];

        const median = history.length
          ? history.reduce((a, b) => a + (b.high + b.low) / 2, 0) /
            history.data.length
          : "none";

        company.stockHistory = history;
        company.median = median;
        company.status = me
          ? matchedUsers.find((item) => item.companyId === company.id)
            ? matchedUsers.find((item) => item.companyId === company.id).status
            : "Not Connected"
          : null;

        return company;
      })
    );

    dispatch({
      type: "SET_PROJECTS",
      payload: data.filter((item) => item.stockHistory.length),
    });
  };
};

/* projects */

/* companies */
export const filterCompanies = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    dispatch({ type: "SET_COMPANIES_LOADING", payload: true });
    const firebase = getFirebase();

    const {
      authReducer: {
        company_search_filters,
        companies_page,
        company_sort_filter,
      },
    } = getState();
    const auth = getState().firebase.auth;
    let me = auth.uid;
    let filter = {
      collection: "companies",
      page: companies_page,
      hitsPerPage: 25,
    };
    const {
      mineral,
      countries,
      searchTerm,
      resourceType,
      averageGrade_min,
      averageGrade_max,
      oreTonnage_min,
      oreTonnage_max,
      exchange,
      filings_after,
      containedMetal_min,
      containedMetal_max,
    } = company_search_filters;

    let queryString = company_sort_filter.name
      ? `&sort=${company_sort_filter.name}:${company_sort_filter.type}&page=${
          companies_page || 1
        }`
      : `&page=${companies_page || 1}`;
    if (searchTerm) {
      queryString = `${queryString}&searchTerm=${searchTerm}`;
    }
    if (mineral) {
      // filter.minerals = [mineral]
      queryString = `${queryString}&minerals=${mineral}`;
    }
    if (filings_after) {
      // filter.minerals = [mineral]
      queryString = `${queryString}&filings_after=${filings_after}`;
    }
    if (countries) {
      queryString = `${queryString}&region=${countries}`;
    }
    if (exchange && exchange.length) {
      queryString = `${queryString}&exchange=${exchange}`;
    }

    if (resourceType) {
      queryString = `${queryString}&mineral_estimate=${resourceType}`;
    }
    if (
      mineral &&
      averageGrade_max > 0 &&
      averageGrade_min !== averageGrade_max
    ) {
      filter["minerals.Grade.depositAggregate"] = [
        averageGrade_min,
        averageGrade_max,
      ];
    }

    if (mineral && oreTonnage_max > 0 && oreTonnage_min !== oreTonnage_max) {
      filter["minerals.Tonnage.depositAggregate"] = [
        oreTonnage_min,
        oreTonnage_max,
      ];
    }

    const data = await apiRequest
      .get(`/search?collection=companies${queryString}`)
      .catch(function (err) {});

    const companies = data ? data.data.data : [];
    let companydata = await Promise.all(
      companies.map(async (company) => {
        // const history = company.yahoo_ticker ? await apiRequest.get(`/stock-history?identifier=${company.yahoo_ticker}`).catch(function (err) {
        //
        // }) : []
        const history = company.stock_history;
        const median =
          history && history.data && history.data.length
            ? history.data.reduce((a, b) => a + (b.high + b.low) / 2, 0) /
              history.data.length
            : "none";
        company.stockHistory = history && history.data ? history.data : [];
        company.projectData = company.projects
          ? company.projects.map((project) => new ProjectModel(project))
          : [];
        company.median = median;
        return company;
      })
    );
    dispatch({
      type: "SET_PROJECT_COMPANIES",
      payload: { companydata, page: data.data.meta.current_page },
    });
    dispatch({
      type: "SET_COMPANIES_PAGE",
      payload: data ? data.data.meta.current_page + 1 : 0,
    });
    dispatch({ type: "SET_COMPANIES_LENGTH", payload: data.data.meta.total });
    dispatch({ type: "SET_COMPANIES_LOADING", payload: false });
  };
};

/* reports */

function createParams(inputObj) {
  const params = {};

  for (const key in inputObj) {
    if (Array.isArray(inputObj[key])) {
      params[key] = inputObj[key].join(",");
    } else if (inputObj[key]) {
      params[key] = inputObj[key];
    } else {
      params[key] = "";
    }
  }

  return params;
}

export const fetchReportDetail = async (payload) => {
  if (payload && payload.report_id) {
    // const companies_data = await apiRequest
    //   .get(`/ni-43101-reports/${payload?.report_id}/companies`)
    //   .catch(function (err) {});
    // const projects_data = await apiRequest
    //   .get(`/ni-43101-reports/${payload?.report_id}/projects`)
    //   .catch(function (err) {});

    return {
      ...payload,

      projects: payload.projects.map((project) => new ProjectModel(project)),
    };
  }
};

export const getReportDetail = (payload) => {
  return async (dispatch, getState) => {
    console.log("getReportDetail action called"); // Debugging log
    const his = payload.history;
    const auth = getState().firebase.auth;
    const firebase = getFirebase();
    const db = firebase.firestore();

    dispatch({ type: "SET_LOADING", payload: true });

    try {
      console.log("Inside try block"); // Debugging log
      let report_payload = await apiRequest.get(
        `/documents/${payload.id}?include=documentSections,projects,companies`
      );

      if (report_payload && report_payload.data) {
        let report = report_payload.data.data;
        report = {
          ...report,
          investors: report.companies,
          projects: report.projects?.map(
            (project) => new ProjectModel(project)
          ),
        };

        dispatch({ type: "SET_REPORT", payload: report });
        dispatch({ type: "SET_LOADING", payload: false });

        his.push({
          pathname: `/report-detail/${report?.id}`,
          state: { ...report },
        });
      } else {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    } catch (err) {
      console.log("Inside catch block", err); // Debugging log
      dispatch({ type: "SET_LOADING", payload: false });
      toast(`${err.response?.data?.message || "An error occurred"}`, {
        type: "error",
        autoClose: 5000,
      });

      his.push({
        pathname: "/auth/login",
      });
    }
  };
};
/* reports */

/* fillings */
const getStrings = (obj) =>
  Object.keys(obj)
    .filter((y) => obj[y])
    .map((a) => {
      return a + "=" + obj[a];
    });

const getQueryString = (obj) =>
  Object.keys(obj)
    .filter((item) => obj[item])
    .map(function (x) {
      if (typeof obj[x] === "object" && !Array.isArray(obj[x])) {
        return getStrings(obj[x]).join(`&${x}:`);
      } else if (Array.isArray(obj[x]) && obj[x].length) {
        return x + "=" + obj[x];
      } else if (Array.isArray(obj[x]) && !obj[x].length) {
        console.log("array", x + "=" + obj[x]);
        return;
      } else {
        return x + "=" + obj[x];
      }
    })
    .join("&");

const serialize = (obj, prefix) => {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] !== null) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? serialize(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
};

const cleanObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName]?.toString() === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const filterReports = (payload) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    dispatch({ type: "SET_REPORTS_LOADING", payload: true });

    const {
      authReducer: { report_sort_filter },
    } = getState();

    const { mineral, countries, searchTerm, resourceType } = payload;

    const report_filters = {};
    let queryString = ``;
    let query = new Query({
      queryParameters: { basefilters: payload },
    });

    const results = getQueryString(payload);

    const params_ = createParams(payload);
    console.log("☎️", params_);

    queryString = report_sort_filter.name
      ? `${queryString}&sort=${report_sort_filter.name}:${report_sort_filter.type}`
      : `${queryString}`;
    queryString = `${queryString}${results.length ? `&${results}` : ""}`;

    const config = {
      params: {
        collection: "documents",
        headline_search: params_.headline,
        country: params_.country,
        exchanges: params_.exchange,
        companies: params_.filing_company,
        authors03: params_.author,
        start_date: payload.date_range?.[0],
        end_date: payload.date_range?.[1],
        all_of_these_words: params_?.all_of?.length
          ? params_?.all_of?.toString()
          : null,
        exact_phrase: params_.exact_phrase,
        minerals: params_.minerals,
        mineral_estimate: params_.mineral_estimate,
        region: params_.region,
        filing_type: "Technical Report",
        page: payload.page || 1,
        pageSize: payload.pageSize || 10,
      },
      paramsSerializer: function (params) {
        return Object.keys(cleanObject(params))
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          )
          .join("&");
      },
    };
    console.log(cleanObject(config.params));

    try {
      const data = await apiRequest.get(`/search`, config);

      if (data.data) {
        const reports = data ? data.data.data : [];
        console.log(reports);
        dispatch({
          type: "SET_REPORTS",
          payload: { reports, page: data?.data?.meta?.current_page },
        });
        dispatch({
          type: "SET_REPORTS_LENGTH",
          payload: data ? data.data.meta.total : 0,
        });
        dispatch({
          type: "SET_REPORTS_PAGE",
          payload: data ? data.data?.meta?.current_page + 1 : 0,
        });

        dispatch({ type: "SET_REPORTS_LOADING", payload: false });
        return Promise.resolve();
      }
    } catch (error) {
      console.log("reports error", error);
      dispatch({
        type: "SET_REPORTS",
        payload: { reports: [], page: 1 },
      });
      dispatch({ type: "SET_REPORTS_LOADING", payload: false });
      return Promise.reject(error);
    }
  };
};
export const filterFilings = (payload) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    dispatch({ type: "SET_FILINGS_LOADING", payload: true });

    const {
      authReducer: { report_sort_filter },
    } = getState();

    const { mineral, countries, searchTerm, resourceType } = payload;

    const report_filters = {};
    let queryString = ``;
    let query = new Query({
      queryParameters: { basefilters: payload },
    });

    const results = getQueryString(payload);

    const params_ = createParams(payload);
    console.log("☎️", params_);

    queryString = report_sort_filter.name
      ? `${queryString}&sort=${report_sort_filter.name}:${report_sort_filter.type}`
      : `${queryString}`;
    queryString = `${queryString}${results.length ? `&${results}` : ""}`;

    const config = {
      params: {
        collection: "documents",
        headline_search: params_.headline,
        country: params_.country,
        exchanges: params_.exchange,
        companies: params_.filing_company,
        authors03: params_.author,
        start_date: payload.start_date,
        end_date: payload.end_date,
        all_of_these_words: params_?.all_of?.length
          ? params_?.all_of?.toString()
          : null,
        exact_phrase: params_.exact_phrase,
        minerals: params_.minerals,
        mineral_estimate: params_.mineral_estimate,
        region: params_.region,
        filing_type: params_.filing_type,
        page: payload.page || 1,
        pageSize: payload.pageSize || 10,
      },
      paramsSerializer: function (params) {
        return Object.keys(cleanObject(params))
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          )
          .join("&");
      },
    };
    console.log(cleanObject(config.params));

    try {
      const data = await apiRequest.get(`/search`, config);

      if (data.data) {
        const reports = data ? data.data.data : [];
        console.log(reports);
        dispatch({
          type: "SET_FILINGS",
          payload: { filings: reports, page: data?.data?.meta?.current_page },
        });
        dispatch({
          type: "SET_FILINGS_LENGTH",
          payload: data ? data.data.meta.total : 0,
        });
        dispatch({
          type: "SET_FILINGS_PAGE",
          payload: data ? data.data?.meta?.current_page + 1 : 0,
        });

        dispatch({ type: "SET_FILINGS_LOADING", payload: false });
        return Promise.resolve();
      }
    } catch (error) {
      console.log("reports error", error);
      dispatch({
        type: "SET_FILINGS",
        payload: { filings: [], page: 1 },
      });
      dispatch({ type: "SET_FILINGS_LOADING", payload: false });
      return Promise.reject(error);
    }
  };
};

// export const filterFilings = (payload) => {
//   return async (dispatch, getState) => {
//     dispatch({ type: "SET_FILINGS_LOADING", payload: true });
//     const {
//       authReducer: { filings_filters, filings_page, filings_sort_filter },
//     } = getState();
//     console.log(filings_filters);

//     const config = {

//       // remove any param if empty
//       params: {
//         collection:'documents',
//         headline: filings_filters.headline ,
//         exchanges: filings_filters.exchange ,
//         companies: filings_filters.filing_company ,
//         filing_type: filings_filters.filing_type ,
//         start_date: filings_filters.date_range?.[0] ,
//         end_date: filings_filters.date_range?.[1] ,
//         all_of_these_words: filings_filters?.all_of?.length ? filings_filters?.all_of?.toString() : null,
//         exact_phrase: filings_filters.exact_phrase ,

//       },
//       paramsSerializer: function (params) {
//         return Object.keys(
//           cleanObject(params)
//         )
//           .map(
//             (key) =>
//               `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
//           )
//           .join("&");
//       },
//     };

//     console.log(cleanObject(config.params));
//     try {
//       const data = await apiRequest.get(
//         `/search`,
//         config
//       );

//       if (data.data) {
//         const filings = data ? data.data.data : [];

//         console.log(filings);
//         dispatch({
//           type: "SET_FILINGS",
//           payload: { filings, page: data?.data?.meta?.current_page },
//         });
//         dispatch({
//           type: "SET_FILINGS_LENGTH",
//           payload: data ? data.data.meta.total : 0,
//         });
//       }
//     } catch (error) {
//       console.log("fillings error", error);
//       // toast(`${error.response.data.error}`, {
//       //   type: "error",
//       //   autoClose: 5000,
//       // });

//       dispatch({
//         type: "SET_FILINGS",
//         payload: { filings: [], page: 1 },
//       });
//     }
//     dispatch({ type: "SET_FILINGS_LOADING", payload: false });
//   };
// };

export const fetchFilingDetail = async (payload) => {
  if (payload && payload.report_id) {
    return {
      ...payload,

      projects: payload.projects.map((project) => new ProjectModel(project)),
    };
  }
};

export const getFilingDetail = (payload) => {
  const his = payload.history;
  console.log(payload);
  return async (dispatch, getState, { getfirebase }) => {
    const auth = getState().firebase.auth;
    dispatch({ type: "SET_LOADING", payload: true });

    let report_payload = await apiRequest
      .get(
        `/documents/${payload.id}?include=documentSections,projects,companies`
      )
      .catch(function (err) {
        console.log(err);
        dispatch({ type: "SET_LOADING", payload: false });
        his.push({
          pathname: "/auth/login",
        });
      });
    if (report_payload && report_payload.data) {
      let report = report_payload.data.data;
      report = {
        ...report,
        investors: report.companies,
        projects: report.projects?.map((project) => new ProjectModel(project)),
      };
      // const report = report_payload.data.data
      if (report) {
        dispatch({ type: "SET_FILING", payload: report });
        dispatch({ type: "SET_LOADING", payload: false });
        his.push({
          pathname: `/filing-detail/${report?.id}`,
          state: { ...report },
        });
      }
    } else {
      dispatch({ type: "SET_LOADING", payload: false });
      // window.open(`https://43-101.com/results.php?sort=1&resultsPerPage=20&datasource=PRSPRM&extended=1&report=${payload.id}`, '_blank')
      // history.back()
    }
  };
};

export const filterCompaniesStaging = (payload) => {
  return async (dispatch, getState) => {
    const {
      authReducer: { companies_page },
    } = getState();
    const data = await apiRequest
      .get(`/companies?page=${companies_page}`)
      .catch(function (err) {});

    const companies = data ? data.data.data : [];
    let companydata = await Promise.all(
      companies.map(async (company) => {
        // const history = company.yahoo_ticker ? await apiRequest.get(`/stock-history?identifier=${company.yahoo_ticker}`).catch(function (err) {
        //
        // }) : []
        const history = company.stock_history;
        const median =
          history && history.data && history.data.length
            ? history.data.reduce((a, b) => a + (b.high + b.low) / 2, 0) /
              history.data.length
            : "none";
        company.stockHistory = history && history.data ? history.data : [];
        company.projectData = company.projects.map(
          (project) => new ProjectModel(project)
        );
        company.median = median;
        return company;
      })
    );

    dispatch({
      type: "SET_PROJECT_COMPANIES",
      payload: {
        companydata,
        page: data ? data.data.meta.current_page + 1 : 0,
      },
    });
    dispatch({
      type: "SET_COMPANIES_PAGE",
      payload: data ? data.data.meta.current_page + 1 : 0,
    });
    dispatch({
      type: "SET_COMPANIES_LENGTH",
      payload: data ? data.data.meta.total : 0,
    });
  };
};

export const getInsightCompanies = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const auth = getState().firebase.auth;
    let me = auth.uid;
    const firebase = getFirebase();
    const db = firebase.firestore();

    const {
      authReducer: { companies_page },
    } = getState();
    const data = await apiRequest
      .get(`/stock-performance?trend=${payload}`)
      .catch(function (err) {});

    const companies = data ? data.data.data : [];
    let companydata = await Promise.all(
      companies.map(async (company) => {
        const history = company.stock_history;
        const median =
          history && history.data.length
            ? history.data.reduce((a, b) => a + (b.high + b.low) / 2, 0) /
              history.data.length
            : "none";
        company.stockHistory = history && history.data ? history.data : [];
        company.projectData = company.projects
          ? company.projects.map((project) => new ProjectModel(project))
          : [];
        company.median = median;
        return company;
      })
    );
    dispatch({ type: "SET_PROJECT_COMPANIES", payload: companydata });
    dispatch({
      type: "SET_COMPANIES_PAGE",
      payload: data ? data.data.meta.current_page + 1 : 0,
    });
    dispatch({ type: "SET_COMPANIES_LENGTH", payload: companydata.length });

    dispatch({ type: "SET_BROWSE_TAB", payload: "companies" });
  };
};
export const getPerformanceAggergates = () => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    //  const  {mineral,interval,range} = payload
    const random = Math.floor(Math.random() * 50 + 1);
    const data = await apiRequest
      .get("/stock-performance?summary_only=true")
      .catch(function (err) {});
    dispatch({
      type: "SET_PERFORMANCE_AGGREGATES",
      payload: data ? data.data.data : {},
    });
  };
};

export const searchAlgoliaCompanies = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const { me } = payload;
    const firebase = getFirebase();
    const db = firebase.firestore();
    const companies = payload.hits.map((snap) => ({
      id: snap.objectID,
      ...snap,
    }));
    let matchedUsers = null;
    if (me) {
      const outgoingMatchRequests = await db
        .collection("matches")
        .where("requestedBy", "==", me)

        .get();

      const pendingMatchRequests = await db
        .collection("matches")
        .where("targetCustomer", "==", me)

        .get();

      const [outgoingMatchesSnap, pendingMatchesSnap] = await Promise.all([
        outgoingMatchRequests,
        pendingMatchRequests,
      ]);

      const outgoingMatchCustomerIds = outgoingMatchesSnap.docs.map((snap) => ({
        id: snap.data().targetCustomer,
        status: snap.data().status,
        companyId: snap.data().targetUserData.companyId,
      }));
      const pendingMatchCustomerIds = pendingMatchesSnap.docs.map((snap) => ({
        id: snap.data().targetCustomer,
        status: snap.data().status,
        companyId: snap.data().targetUserData.companyId,
      }));

      matchedUsers = [
        ...new Set([...outgoingMatchCustomerIds, ...pendingMatchCustomerIds]),
      ];
    }

    let data = await Promise.all(
      companies.map(async (company) => {
        let projects = await db
          .collection("projects")
          .where("companyData.id", "==", company.id)
          .get();
        const projectsData = projects.docs.map((snap) => ({
          id: snap.id,
          ...snap.data(),
        }));

        // const history = (await apiRequest.get(`/stock-history?identifier=${company.yahooTicker}`)).data.data
        const history = company.stock_history;
        const median = history.data.length
          ? history.data.reduce((a, b) => a + (b.high + b.low) / 2, 0) /
            history.data.length
          : "none";
        company.projects_data = projectsData.map(
          (project) => new ProjectModel(project)
        );
        company.stockHistory = history && history.data ? history.data : [];
        company.median = median;
        company.status = me
          ? matchedUsers.find((item) => item.companyId === company.id)
            ? matchedUsers.find((item) => item.companyId === company.id).status
            : "Not Connected"
          : null;

        return company;
      })
    );

    dispatch({
      type: "SET_PROJECT_COMPANIES",
      payload: data.filter((item) => item.stockHistory.length),
    });
  };
};

export const getCompanyNews = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();

    //  const  {mineral,interval,range} = payload
    await apiRequest
      .get(
        `/search?collection=documents&filing_company=${
          payload.searchTerm || ""
        }&page=${payload.startNumber}&limit=10`
      )
      .then((result) => {
        dispatch({
          type: "SET_COMPANY_NEWS",
          payload: {
            data: result.data.data || [],
            next: result.data.meta.curremt_page + 1,
            charts: result.data.chartData,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_COMPANY_NEWS",
          payload: { data: [], next: 0, charts: null },
        });
      });
  };
};

export const getAllMinerals = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();

    await apiRequest
      .get(`/minerals`)
      .then((result) => {
        dispatch({
          type: "SET_MINERALS_LIST",
          payload: {
            data: result.data.data || [],
          },
        });
      })
      .catch((error) => {
        dispatch({ type: "SET_MINERALS_LIST", payload: { data: [] } });
      });
  };
};

export const getCompanySocial = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    await apiRequest
      .get(`/nlsearch?datasource=social&searchTerm=${payload.searchTerm || ""}`)
      .then((result) => {
        dispatch({
          type: "SET_COMPANY_SOCIAL",
          payload: {
            data: result.data.data || [],
            next: result.data.meta.nextRecordAvailable,
            charts: result.data.chartData,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_COMPANY_SOCIAL",
          payload: { data: [], next: 0, charts: null },
        });
      });
  };
};

export const fetchfullcompanyDetail = async (payload) => {
  const projectData = await apiRequest
    .get(`/companies/${payload}`)
    .catch(function (error) {});

  const project = projectData ? projectData.data.data : null;
  if (project) {
    const project_years = await apiRequest
      .get(`/companies/${payload}/production-years`)
      .catch((err) => console.log("err"));
    if (project_years && project_years.data.data) {
      project.annual_prod_years =
        project_years.data.data.annual_production_years.sort().reverse();
      project.quarterly_prod_years =
        project_years.data.data.quarterly_production_years.sort().reverse();
    }
    const mrr_years = await apiRequest
      .get(`/companies/${payload}/mrr-annual-growth`)
      .catch((err) => console.log("err"));
    if (mrr_years && mrr_years.data.data) {
      project.mrr_years = _.uniq(mrr_years.data.data.map((item) => item.label));
    }

    const fundermental_details = await apiRequest
      .get(`/companies/${payload}/fundamental-details`)
      .catch((err) => console.log("err"));

    if (fundermental_details && fundermental_details.data.data) {
      project.fundermental_details = fundermental_details.data.data;
    }

    const history =
      project.stock_history && project.stock_history.data
        ? project.stock_history.data
        : [];
    const median =
      history && history.length
        ? history.reduce((a, b) => a + (b.high + b.low) / 2, 0) / history.length
        : "none";
    project.stockHistory = history;

    // let projects = await ('projects').where('companyData.id', '==', payload.id).get()
    // const projectsData = projects.docs.map(snap => (
    //   {
    //     id: snap.id,
    //     ...snap.data()
    //   }))

    // to be changed
    project.projects_data =
      project.projects?.map((project) => new ProjectModel(project)) || [];
    project.median = median;
  }
  return project;
};

export const fetchcompanyDetailExtra = async (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    let project = getState().authReducer.selectedCompany;
    const project_years = await apiRequest
      .get(`/companies/${payload}/production-years`)
      .catch((err) => console.log("err"));
    if (project_years && project_years.data.data) {
      project.annual_prod_years =
        project_years.data.data.annual_production_years.sort().reverse();
      project.quarterly_prod_years =
        project_years.data.data.quarterly_production_years.sort().reverse();
    }
    const mrr_years = await apiRequest
      .get(`/companies/${payload}/mrr-annual-growth`)
      .catch((err) => console.log("err"));
    if (mrr_years && mrr_years.data.data) {
      project.mrr_years = _.uniq(mrr_years.data.data.map((item) => item.label));
    }

    const fundermental_details = await apiRequest
      .get(`/companies/${payload}/fundamental-details`)
      .catch((err) => console.log("err"));

    if (fundermental_details && fundermental_details.data.data) {
      project.fundermental_details = fundermental_details.data.data;
    }

    const history =
      project.stock_history && project.stock_history.data
        ? project.stock_history.data
        : [];
    const median =
      history && history.length
        ? history.reduce((a, b) => a + (b.high + b.low) / 2, 0) / history.length
        : "none";
    project.stockHistory = history;

    // let projects = await ('projects').where('companyData.id', '==', payload.id).get()
    // const projectsData = projects.docs.map(snap => (
    //   {
    //     id: snap.id,
    //     ...snap.data()
    //   }))

    // to be changed
    project.projects_data =
      project.projects?.map((project) => new ProjectModel(project)) || [];
    project.median = median;
  };
};

export const fetchcompanyDetail = async (payload) => {
  let project = payload;
  return project;
};
export const getCompanyDetail = (payload) => {
  const his = payload.history;

  return async (dispatch, getState, { getfirebase }) => {
    dispatch({ type: "SET_LOADING", payload: true });
    dispatch({ type: "SET_COMPANY_LOADING", payload: true });
    const auth = getState().firebase.auth;
    let me = auth.uid;
    const firebase = getFirebase();
    const db = firebase.firestore();
    his.push({
      pathname: `/company-detail/${payload.id}`,
    });

    const company = await fetchfullcompanyDetail(payload.id);

    dispatch({ type: "SET_COMPANY", payload: company });
    dispatch({ type: "SET_COMPANY_LOADING", payload: false });
    dispatch({ type: "SET_LOADING", payload: false });
    // if (company) {
    // console.log("🔑 company caleed", company)

    // }
  };
};
/* companies */

export const getRecommendedResources = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const firebase = getFirebase();
    //  const  {mineral,interval,range} = payload
    const random = Math.floor(Math.random() * 50 + 1);
    const data = await apiRequest
      .get(`/projects?page_size=10&page=${random}`)
      .catch(function (err) {});

    const projects = data ? data.data.data : [];
    let projectData = await Promise.all(
      projects.map(async (project) => {
        let companydata = project.investors
          ? await Promise.all(
              project.investors.map(async (company) => {
                // const history = company.yahoo_ticker ? await apiRequest.get(`/stock-history?identifier=${company.yahoo_ticker}`).catch(function (err) {
                //
                // }) : []
                const history = company.stock_history;
                const median =
                  history && history.length
                    ? history.reduce((a, b) => a + (b.high + b.low) / 2, 0) /
                      history.data.length
                    : "none";
                company.stockHistory =
                  history && history.data ? history.data : [];

                company.median = median;
                return company;
              })
            )
          : [];
        project.investors = companydata;
        return project;
      })
    );
    dispatch({
      type: "SET_RECOMMENDED_PROJECTS",
      payload: projectData.map((project) => new ProjectModel(project)),
    });
  };
};

export const insightsNews = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    let queryString = "";
    dispatch({ type: "SET_INSIGHTS_LOADING", payload: true });
    await apiRequest
      .get(
        `/search?collection=mining_news&start_date=${payload.start_date}&end_date=${payload.end_date}`
      )
      .then((result) => {
        // await apiRequest.get(`/search?collection=mining_news`).then((result) => {
        dispatch({
          type: "SET_INSIGHTS_NEWS",
          payload: {
            data: result.data.data || [],
            next:
              result.data.page + 1 < result.data.nbPages
                ? result.data.page + 1
                : 0,
            charts: result.data.chartData,
          },
        });
        dispatch({ type: "SET_INSIGHTS_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({
          type: "SET_INSIGHTS_NEWS",
          payload: { data: [], next: 0, charts: null },
        });
        dispatch({ type: "SET_INSIGHTS_LOADING", payload: false });
      });
  };
};

export const companyConnect = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    const auth = getState().firebase.auth;
    let me = auth.uid;
    let activeUser = getState().authReducer.activeUser;
    const firebase = getFirebase();
    const db = firebase.firestore();
    const his = payload.history;

    let chat = await db
      .collection("chats")
      .doc(`${payload.company_id}-${me}`)
      .get();

    if (chat.exists) {
      his.push({
        pathname: "/connections",
      });
    } else {
      const staff = await apiRequest.get(
        `/companies/${payload.company_id}/staff`
      );
      let memberNames = {};
      memberNames[me] = activeUser?.name;

      if (staff) {
        let members = [me, ...staff.data.data.map((item) => item.uid)];
        await staff.data.data.forEach((item) => {
          memberNames[item.uid] = item.name;
        });
        const data = {
          type: "group",
          name: `${payload.company_name},${activeUser?.name}`,
          members,
          memberNames,
          createdBy: me,
          lastText: staff.data.data
            .map((member) => member.name.split(" ")[0])
            .slice(0, 3)
            .join(", "),
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        };
        await db
          .collection("chats")
          .doc(`${payload.company_id}-${me}`)
          .set(data)
          .then((doc) => {
            his.push({
              pathname: "/connections",
            });
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });

        his.push({
          pathname: "/connections",
        });
      }

      // const group = await db.collection('customers').add(payload).catch((err) => {
      //   //   dispatch({ type: 'INVITE_SUCCESS' })
      //   // })
      // })
    }

    // const callableReturnMessage = firebase.functions().httpsCallable('companyMatchRequest');
    // callableReturnMessage({ companyId: payload }).then((result) => {
    //   dispatch(getProspects())
    //   dispatch(getCompanies())
    // }).catch((error) => {
    // });
  };
};

export const sendInvite = (payload) => {
  return async (dispatch, getState, { getfirebase }) => {
    dispatch({ type: "INVITE_REQUEST" });
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    const data = await apiRequest.post("/meetings", payload).catch((error) => {
      console.error("Error adding document: ", error);
    });
    if (data) {
      dispatch({ type: "INVITE_SUCCESS" });
      getMeetings();
    }
  };
};

export const manageSub = () => {
  return async () => {
    const link = await (
      await apiRequest.get("/me/billing-portal")
    ).data.billing_portal;
    window.open(link, "_blank");
  };
};
const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const updateUserProfile = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: "UPDATE_USER_PROFILE_REQUEST" });

    const auth = getState().authReducer.activeUser;
    var formData = new FormData();

    formData.append("file", payload);
    const data = await apiRequest
      .post(`/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch(function (err) {
        dispatch({ type: "UPDATE_USER_PROFILE_FAIL", payload: err.message });
      });

    if (data) {
      const userdata = await apiRequest
        .patch(`/users/${auth.id}`, { profile_image_id: data.data.data.id })
        .catch(function (err) {
          dispatch({ type: "UPDATE_USER_PROFILE_FAIL", payload: err.message });
        });

      if (userdata) {
        let user = userdata.data.data;
        if (user && user.current_company_id) {
          let companyData = await fetchcompanyDetail(user.current_company_id);
          user.companyData = companyData;
        }
        if (user && user.profile_image_id) {
          const profileUrl = await apiRequest
            .get(`/files/${user.profile_image_id}`)
            .catch(function (err) {});
          user.profileUrl = profileUrl.data.data.url;
        }
        dispatch({ type: "UPDATE_USER_PROFILE_SUCCESS", payload: user });
      }
    }
  };
};
export const updateUserDetails = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: "UPDATE_USER_REQUEST" });

    const auth = getState().authReducer.activeUser;

    const data = await apiRequest
      .patch(`/users/${auth.id}`, payload)
      .catch(function (err) {});
    const companydata = await apiRequest
      .post(`/companies/${payload.current_company_id}/staff`, {
        user_id: auth.id,
        position: payload.current_company_position,
      })
      .catch(function (err) {});
    if (data) {
      const userdata = await apiRequest
        .get(`/users/${auth.id}`)
        .catch(function (err) {
          //
          dispatch({ type: "UPDATE_USER_FAIL", payload: err });
        });

      if (userdata) {
        let user = userdata.data.data;
        if (user && user.current_company_id) {
          let companyData = await fetchcompanyDetail(user.current_company_id);
          user.companyData = companyData;
        }
        dispatch({ type: "UPDATE_USER_SUCCESS", payload: user });
      }
    }
  };
};

export const getBillingInfo = () => {
  return async (dispatch, getState) => {
    const auth = getState().authReducer.activeUser;
    if (auth) {
      const userdata = await apiRequest
        .get(`/stripe-customer/${auth.id}`)
        .catch(function (err) {
          //
          dispatch({
            type: "FETCH_USER_SUBSCRIPTION_FAIL",
            payload: err.message,
          });
        });

      if (userdata) {
        let stripeData = userdata.data.data;
        dispatch({
          type: "FETCH_USER_SUBSCRIPTION_SUCCESS",
          payload: stripeData,
        });
      }
    }
  };
};

export const checkUser = () => {
  return async (dispatch, getState) => {
    const auth = getState().authReducer.activeUser;
    if (auth) {
      const userdata = await apiRequest
        .get(`/users/${auth.id}`)
        .catch(function (err) {
          //
          dispatch({ type: "UPDATE_USER_PROFILE_FAIL", payload: err.message });
        });

      if (userdata) {
        let user = userdata.data.data;

        if (user && user.current_company_id) {
          let companyData = await fetchcompanyDetail(user.current_company_id);
          user.companyData = companyData;
        }
        if (user && user.profile_image_id) {
          const profileUrl = await apiRequest
            .get(`/files/${user.profile_image_id}`)
            .catch(function (err) {});
          user.profileUrl = profileUrl ? profileUrl.data.data.url : null;
        }
        if (
          JSON.stringify(_.omit(auth, "__jsogObjectId")) !==
          JSON.stringify(user)
        ) {
          dispatch({ type: "UPDATE_USER_PROFILE_SUCCESS", payload: user });
        }
      }
    }
  };
};
export const getMeetings = () => {
  return async (dispatch, getState, { getfirebase }) => {
    const data = await apiRequest
      .get("/meetings?page_size=50")
      .catch(function (err) {
        //
        dispatch({ type: "SET_MEETINGS", payload: [] });
      });
    const messages = data ? data.data.data : [];
    dispatch({ type: "SET_MEETINGS", payload: messages });
  };
};
