import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Chip,
  Box,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { agentTypes } from "./filterConstants";

const FiltersPopup = ({ open, onClose, onApplyFilters, selectedAgent }) => {
  const [filters, setFilters] = useState({
    countries: [],
    minerals: [],
    projectStages: [],
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 12))
      .toISOString()
      .split("T")[0], // 12 months ago
    endDate: new Date().toISOString().split("T")[0], // today
  });

  const [filterCountries, setFilterCountries] = useState([]);
  const [filterMinerals, setFilterMinerals] = useState([]);

  const projectStages = [
    "Grassroots",
    "Production",
    "Target Drilling",
    "Discovery Delineation",
    "Permitting & Feasibility",
    "Suspended",
    "PEA",
    "Prefeasibility",
    "Construction",
    "Closed",
  ];

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleApply = () => {
    onApplyFilters(filters);
    onClose();
  };

  const getFilterData = async (isMounted) => {
    try {
      const [countriesResponse, mineralsResponse] = await Promise.all([
        axios.get("https://app.prospectormatch.com/api/countries"),
        axios.get("https://app.prospectormatch.com/api/minerals"),
      ]);

      // Only update state if the component is still mounted
      if (isMounted.current) {
        if (countriesResponse?.data?.data) {
          const countryNames = countriesResponse.data.data.map(
            (country) => country.name
          );
          setFilterCountries(countryNames);
        }

        if (mineralsResponse?.data?.data) {
          const mineralNames = mineralsResponse.data.data.map(
            (mineral) => mineral.name
          );
          setFilterMinerals(mineralNames);
        }
      }
    } catch (error) {
      console.error("Error fetching filter data:", error);
    }
  };

  const setDefaultDates = (selectedAgent) => {
    const agentName = selectedAgent.name;
    // Set appropriate date ranges based on agent type
    let startDate;
    let endDate;
    switch (agentName) {
      case "Mining Hot News Analyst":
      case "Production Analyst":
      case "M&A Analyst":
      case "Drilling Analyst":
        // Last 14 days for hot news
        // startDate = new Date(new Date().setDate(new Date().getDate() - 14)).toISOString().split('T')[0];
        startDate = new Date(new Date().setDate(new Date().getDate() - 365))
          .toISOString()
          .split("T")[0];
        endDate = new Date().toISOString().split("T")[0];
        break;

      case "Technical Report Analyst":
        // Last 1 year
        startDate = new Date(new Date().setDate(new Date().getDate() - 365))
          .toISOString()
          .split("T")[0];
        // startDate = new Date(new Date().setDate(new Date().getDate() - 14)).toISOString().split('T')[0];
        endDate = new Date().toISOString().split("T")[0];
        break;

      case "Geology and Metallurgy Research Analyst":
        // Last 5 years
        startDate = new Date(new Date().setDate(new Date().getDate() - 1825))
          .toISOString()
          .split("T")[0];
        // startDate = new Date(new Date().setDate(new Date().getDate() - 1825)).toISOString().split('T')[0];
        endDate = new Date().toISOString().split("T")[0];
        break;

      default:
        // Default to last 2 months for other agent types
        // startDate = new Date(new Date().setMonth(new Date().getMonth() - 2)).toISOString().split('T')[0];
        startDate = new Date(new Date().setDate(new Date().getDate() - 365))
          .toISOString()
          .split("T")[0];
        endDate = new Date().toISOString().split("T")[0];
        break;
    }
    setFilters((prev) => {
      const newFilters = {
        ...prev,
        startDate: startDate,
        endDate: endDate,
      };
      onApplyFilters(newFilters);
      return newFilters;
    });
  };

  useEffect(() => {
    if (selectedAgent) {
      setDefaultDates(selectedAgent);
    }
  }, [selectedAgent]);

  useEffect(() => {
    const isMounted = { current: true };
    getFilterData(isMounted);

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Box x={{ display: "flex", flexDirection: "column", gap: "100px" }}>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Chat Filters</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              mt: 2,
              minWidth: 300,
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <FormControl fullWidth sx={{ mb: 10 }}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={filters.startDate}
                  onChange={(e) =>
                    handleFilterChange("startDate", e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 10 }}>
                <TextField
                  label="End Date"
                  type="date"
                  value={filters.endDate}
                  onChange={(e) =>
                    handleFilterChange("endDate", e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Box>
            <FormControl fullWidth sx={{ mb: 10 }}>
              <InputLabel>Countries</InputLabel>
              <Select
                multiple
                value={filters.countries}
                label="Countries"
                onChange={(e) =>
                  handleFilterChange("countries", e.target.value)
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {filterCountries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Minerals</InputLabel>
              <Select
                multiple
                value={filters.minerals}
                label="Minerals"
                onChange={(e) => handleFilterChange("minerals", e.target.value)}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {filterMinerals.map((mineral) => (
                  <MenuItem key={`mineral-${mineral}`} value={mineral}>
                    {mineral}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Project Stages</InputLabel>
              <Select
                multiple
                value={filters.projectStages}
                label="Project Stages"
                onChange={(e) =>
                  handleFilterChange("projectStages", e.target.value)
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {projectStages.map((stage) => (
                  <MenuItem key={stage} value={stage}>
                    {stage}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleApply} variant="contained" color="primary">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FiltersPopup;
