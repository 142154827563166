/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useLayoutEffect,
} from "react";
import { connect, useSelector } from "react-redux";
import {
  MainPage,
  Headline5,
  Body1,
  Primary,
  Subtitle1,
  Subtitle2,
  Button,
  GreyBox,
  ComponentContainer,
  Body2,
  Hexagon,
  SocialsLink,
  Body3,
  TailwindButton,
  TailwindButtonOutline,
  Headline6,
} from "../../components";
import { Grid, Divider } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import ProjectCard from "../../components/ProjectCard";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import {
  getCommodityData,
  getCompanyDetail,
  getCompanyNews,
  getCompanySocial,
  companyConnect,
  matchUsers,
  manageSub,
  apiRequest,
  getCommpanyStockData,
} from "../../store/Actions/AuthActions";
import GoogleMapReact from "google-map-react";
import MapMarker from "../../components/CompanyMapMarker";
import Dialog from "@material-ui/core/Dialog";
import Notes from "../../components/Notes";
import List from "../../components/List";
import EditNotes from "../../components/UserNotes/EditNotes";
import Sparkline from "../../components/SparkLine";
import { RadialChart } from "./Components/RadialChart";
import StockPerformance from "./Components/StockPerformance";
import { DashboardModalcontent } from "./Components/DashboardModalcontent";
import VideoThumbnail from "react-video-thumbnail"; // use npm published version
import ReactPlayer from "react-player/lazy";

import CompanyProduction from "./Components/Content/Production/CompanyProduction";
import QurterlyCompanyProduction from "./Components/Content/QuaterlyProduction/CompanyProduction";
import {
  LinkedIn,
  AlternateEmail,
  Twitter,
  Language,
  Facebook,
  Instagram,
} from "@material-ui/icons";
import CompanyResources from "./Components/Content/CompanyResources";
import BoardMembers from "./Components/BoardMembers";
import { useMediaQuery } from "react-responsive";
import { Popover, Transition } from "@headlessui/react";
import { nFormatter } from "../../configure/helpers";
import ReactGA from "react-ga4";
import CompanyProjects from "./Components/CompanyProjects";
import CompanyFinancialStatements from "./Components/CompanyFinancialStatements";
// ../public/firebase-messaging-sw.js
// import {firebaseAnalytics} from "../../public/firebase-messaging-sw.js"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const CompanyDetail = ({
  companyConnect,
  matchUsers,
  getCompanyDetail,
  statistics,
  activeUser,
  user_follow_list,
  setSubscription,
  history,
  company,
  getCompanyNews,
  setCommodity,
  news_feed,
  social_feed,
  getCompanySocial,
  getCommodityData,
  manageSub,
  company_loading,
}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1224px)",
  });
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const [dashboardModalVisible, setdashboardModalVisible] = useState(false);
  const [modalContent, setmodalContent] = useState(null);
  const [allProjects, setallProjects] = useState(false);
  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
    diff: 0,
  });
  const location = useLocation();
  let subscription =
    activeUser && activeUser?.subscriptions ? activeUser?.subscriptions : [];
  useEffect(() => {
    let id = location.pathname.replace("/company-detail/", "");
    if ((!company || company.id !== id) && !company_loading) {
      console.log("🤬 wtf company caleed from page", company_loading);
      getCompanyDetail({ id, history });
    }
  }, [location]);
  useEffect(() => {
    ReactGA.event({
      category: company?.name,
      action: "company_viewed",
    });
    ReactGA.gtag("config", "G-JB0KC590Z4", {
      company_id: company?.id,
      company_name: company?.name,
      yahoo_ticker: company?.yahoo_ticker,
      client: activeUser?.email,
    });
  }, [company]);

  useEffect(() => {
    if (activeUser) {
      subscription =
        activeUser && activeUser?.subscriptions
          ? activeUser?.subscriptions
          : [];
    }

    return () => {};
  }, [activeUser]);
  useEffect(() => {
    if (company) {
      setexistingNote(null);
      checkForNotes();
      getPeople();
      selectComodity();
      calculateTrend();
      fetchCompanyStuff();
      resetCenter();
      fetchCooporateMedia();
    }

    return () => {};
  }, [company, history]);
  useEffect(() => {
    resetCenter();
    return () => {};
  }, []);

  const [members, setMembers] = useState(null);
  const getPeople = async () => {
    const data = await apiRequest
      .get(`companies/${company.id}/people`)
      .catch((err) => setMembers(null));
    if (data && data.data) {
      setMembers(data.data.data);
    }
  };
  // observer
  function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    useEffect(() => {
      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        observer.disconnect();
      };
    }, []);

    return isIntersecting;
  }
  const [visibleItersection, setvisibleItersection] =
    useState("overviewisVisible");
  const render_stuff = () => {
    let i = [
      {
        value: Math.abs(overviewRef.current?.getBoundingClientRect().top),
        name: "overviewisVisible",
      },
      {
        value: Math.abs(locationRef.current?.getBoundingClientRect().top),
        name: "locationisVisible",
      },
      {
        value: Math.abs(reportsRef.current?.getBoundingClientRect().top),
        name: "reportsisVisible",
      },
      {
        value: Math.abs(RRRef.current?.getBoundingClientRect().top),
        name: "RRisVisible",
      },
      {
        value: Math.abs(mediaRef.current?.getBoundingClientRect().top),
        name: "mediaisVisible",
      },
      {
        value: Math.abs(productionRef.current?.getBoundingClientRect().top),
        name: "productionisVisble",
      },
      {
        value: Math.abs(boardRef.current?.getBoundingClientRect().top),
        name: "boardisVisble",
      },
    ];

    setvisibleItersection(_.sortBy(i, "value")[0].name);
  };

  const reportsRef = useRef();
  const overviewRef = useRef();
  const locationRef = useRef();
  const RRRef = useRef();
  const mediaRef = useRef();
  const productionRef = useRef();
  const boardRef = useRef();
  const reportsisVisible = useOnScreen(reportsRef);
  const overviewisVisible = useOnScreen(overviewRef);
  const locationisVisible = useOnScreen(locationRef);

  const calculateTrend = () => {
    let stock = [...company.stockHistory];
    let sortStock = _.sortBy(stock, function (dateObj) {
      return new Date(dateObj.date);
    });
    let diff =
      stock.length && stock.length > 1
        ? sortStock[sortStock.length - 1].close -
          sortStock[sortStock.length - 2].close
        : 0;
    let trendState =
      stock.length && stock.length > 1
        ? sortStock[sortStock.length - 1].close -
            sortStock[sortStock.length - 2].close >=
          0
          ? "up"
          : "down"
        : "up";
    let ratio =
      stock.length && stock.length > 1
        ? Math.abs(diff / sortStock[sortStock.length - 2].close) * 100
        : 0;

    setTrend({
      ratio: trendState === "up" ? ratio.toFixed(2) : `-${ratio.toFixed(2)}`,
      trend: trendState,
      diff,
    });
  };
  const fetchCompanyStuff = () => {
    getCompanyNews({ searchTerm: company.name, datasource: "news" });
    getCompanySocial({ searchTerm: company.name, datasource: "social" });
  };
  const [contacts, setContacts] = useState([]);
  const [media, setMedia] = useState([]);
  const fetchCooporateMedia = async () => {
    let id = location.pathname.replace("/company-detail/", "");
    const socials = await apiRequest
      .get(`/companies/${id}/socials`)
      .catch((e) => console.log(e));
    const media = await apiRequest
      .get(`/companies/${id}/media`)
      .catch((e) => console.log(e));
    if (socials && socials.data) {
      setContacts(socials.data.data);
    }
    if (media && media.data) {
      setMedia(media.data.data);
    }
  };
  const [center, setCenter] = useState({
    lat: parseFloat(
      company && company.projects && company.projects?.length
        ? company.projects[0].lat
        : 0.0
    ),
    lng: parseFloat(
      company && company.projects && company.projects?.length
        ? company.projects[0].lng
        : 0.0
    ),
  });
  const more_news = () => {
    getCompanyNews({
      searchTerm: company.name,
      datasource: "news",
      startNumber: news_feed.next,
    });
  };
  const more_socials = () => {
    getCompanySocial({
      searchTerm: company.name,
      datasource: "news",
      startNumber: social_feed.next,
    });
  };
  const selectComodity = () => {
    if (company.yahoo_ticker) {
      let data = {
        id: company.id,
        interval: "1d",
        identifier: company.yahoo_ticker,
        range: "2y",
      };
      setCommodity(company.yahoo_ticker);
      getCommodityData(data);
    }
  };
  const resetCenter = () => {
    setCenter({
      lat: parseFloat(
        company && company.projects && company.projects?.length
          ? company.projects[0].lat
          : 0.0
      ),
      lng: parseFloat(
        company && company.projects && company.projects?.length
          ? company.projects[0].lng
          : 0.0
      ),
    });
  };
  const [NotesOpen, setNotesOpen] = useState(false);
  const handleClickOpen = () => {
    setNotesOpen(true);
  };

  const handleClose = (value) => {
    setNotesOpen(false);
    checkForNotes();
  };
  const [ListOpen, setListOpen] = useState(false);
  const handleListOpen = () => {
    setListOpen(true);
  };

  const handleListClose = (value) => {
    setListOpen(false);
  };
  const [existingNote, setexistingNote] = useState(null);
  const checkForNotes = async () => {
    const response = await apiRequest
      .get(`/companies/${company.id}/notes`)
      .catch((error) => {});
    if (response && response.data.data) {
      setexistingNote(response.data.data);
    }
  };
  const handleOpenDashmodal = (content) => {
    setmodalContent(content);
    setdashboardModalVisible(true);
  };
  const handleDAshModalClose = () => {
    setdashboardModalVisible(false);
  };

  useEffect(() => {
    setselected_mineral(company?.annual_prod_minerals?.[0]);
    return () => {};
  }, [company]);

  const [selected_mineral, setselected_mineral] = useState(
    company?.annual_prod_minerals?.[0]
  );

  useEffect(() => {
    let id = location.pathname.replace("/company-detail/", "");
    const script = document.createElement("script");
    script.src =
      "https://copilot-companies.prospectormatch.com/copilot/index.js";
    script.async = true;
    document.body.appendChild(script);

    window.mountChainlitWidget({
      chainlitServer: "https://copilot-companies.prospectormatch.com",
    });

    const handleKeyDown = (e) => {
      if (!e.metaKey && !e.ctrlKey && !e.altKey) {
        if (/^[a-zA-Z]$/.test(e.key)) {
          e.stopPropagation();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown, true);

    script.cleanup = () => {
      document.removeEventListener("keydown", handleKeyDown, true);
    };

    window.addEventListener("chainlit-call-fn", (e) => {
      const { name, args, callback } = e.detail;
      if (name === "getCompanyId") {
        console.log(name, args);
        callback(id);
      } else if (name === "getAgent") {
        callback("Company-Specific Mining Analyst");
      }
    });

    return () => {
      window.unmountChainlitWidget();
      const chainlit_el = document.getElementById("chainlit-copilot");
      document.body.removeChild(chainlit_el);
    };
  }, [company]);

  return (
    <MainPage onScroll={render_stuff}>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={NotesOpen}
      >
        {existingNote ? (
          <EditNotes
            handleClose={handleClose}
            type={"company"}
            entity={existingNote}
          />
        ) : (
          <Notes handleClose={handleClose} type={"company"} entity={company} />
        )}
      </Dialog>
      <Dialog
        onClose={handleListClose}
        aria-labelledby="simple-dialog-title"
        open={ListOpen}
      >
        <List handleClose={handleListClose} type={"company"} entity={company} />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleDAshModalClose}
        aria-labelledby="simple-dialog-title"
        open={dashboardModalVisible}
      >
        <DashboardModalcontent content={modalContent} />
      </Dialog>
      {company && !company_loading ? (
        <Grid container spacing={1} className="company-page-wrapper">
          {!isTabletOrMobileDevice ? (
            <Grid item xs={2} className="project-detail-wrapper">
              <div style={{ padding: "5px", position: "sticky", top: 0 }}>
                <div>
                  <div className="flex-boy-row w-full">
                    <div className="mx-2" style={{ marginRight: "20px" }}>
                      <div className="company-ticker">
                        <Subtitle1
                          onClick={() =>
                            getCompanyDetail({ id: company.id, history })
                          }
                        >
                          {company.ticker && company.exchange
                            ? ` ${company.ticker}.${company.exchange}`
                            : company.name}
                        </Subtitle1>

                        <Body2
                          onClick={() =>
                            getCompanyDetail({ id: company.id, history })
                          }
                        >
                          {/* {company.name} //removed because of duplication of company name */}
                        </Body2>
                      </div>
                    </div>

                    <div style={{ flex: 1, marginTop: "10px" }}>
                      <div className="tiny-spark float-right">
                        <Sparkline
                          company={company}
                          color={trend.trend !== "up" ? "#B00020" : "#00AC69"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex-boy-row flex-wrap">
                    <div
                      className="flex-boy-row mx-2"
                      style={{ flex: 1, justifyContent: "normal" }}
                    >
                      {[...new Set(company.minerals)]
                        .slice(0, 4)
                        .filter(
                          (item) =>
                            item !== "Not" &&
                            item !== "Combined" &&
                            item !== "Metric Tonnes"
                        )
                        .map((min, index) => {
                          return (
                            <Hexagon
                              key={index}
                              width={28}
                              style={{ fontSize: "0.6rem" }}
                            >
                              {elements.find(
                                (item) =>
                                  item.name.toLowerCase() ===
                                  min.trim().toLowerCase()
                              )
                                ? elements
                                    .find(
                                      (item) =>
                                        item.name.toLowerCase() ===
                                        min.trim().toLowerCase()
                                    )
                                    .chemical_symbol.substring(0, 4)
                                : min.substring(0, 4).substring(0, 4)}
                            </Hexagon>
                          );
                        })}
                    </div>
                    <div className="flex-1 m-2">
                      <GreyBox
                        className="text-sm xl:float-right"
                        style={{ width: "100px" }}
                      >
                        {company.projects_count} Projects
                      </GreyBox>
                    </div>
                  </div>
                </div>
                <Divider />

                <RadialChart company={company} />

                <div
                  className="flex-boy-row flex-wrap m-2"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <TailwindButton
                    onClick={() =>
                      activeUser
                        ? setNotesOpen(true)
                        : history.push("/auth/login")
                    }
                    text={`${existingNote ? "Edit" : "+ Add"} Notes`}
                  />

                  {contacts && contacts.length
                    ? contacts.map((item) => {
                        return (
                          <SocialsLink
                            onClick={() => {
                              if (item.platform !== "email") {
                                window.open(item.url, "_blank");
                              }
                            }}
                          >
                            {item.platform === "linkedin" ? (
                              <LinkedIn fontSize="medium" />
                            ) : item.platform === "twitter" ? (
                              <Twitter fontSize="medium" />
                            ) : item.platform === "email" ? (
                              <AlternateEmail fontSize="medium" />
                            ) : item.platform === "instagram" ? (
                              <Instagram fontSize="medium" />
                            ) : item.platform === "facebook" ? (
                              <Facebook fontSize="medium" />
                            ) : (
                              <Facebook fontSize="medium" />
                            )}
                          </SocialsLink>
                        );
                      })
                    : null}
                  <div className="my-2">
                    {activeUser &&
                    activeUser?.permissions?.includes("follow_resources") ? (
                      user_follow_list?.includes(company.id) ? (
                        <TailwindButton
                          text="Following"
                          onClick={() => setListOpen(true)}
                        />
                      ) : user_follow_list
                          ?.map((item) => item.list_entries)
                          ?.flat()
                          ?.includes(company.id) ? (
                        <TailwindButton
                          text="Following"
                          onClick={() => setListOpen(true)}
                        />
                      ) : (
                        <TailwindButtonOutline
                          onClick={() => setListOpen(true)}
                          text="Follow"
                        />
                      )
                    ) : (
                      <TailwindButtonOutline
                        onClick={() =>
                          activeUser
                            ? history.push("/upgrade")
                            : history.push("/auth/login")
                        }
                        text="Connect"
                      />
                    )}
                  </div>
                </div>

                <div className="page-anchors">
                  <Subtitle1
                    color={
                      visibleItersection === "overviewisVisible" && Primary
                    }
                    style={{ marginBottom: "20px" }}
                    onClick={(e) =>
                      overviewRef.current?.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                  >
                    1. Company Overview
                  </Subtitle1>
                  <Subtitle1
                    style={{ marginBottom: "20px" }}
                    color={
                      visibleItersection === "locationisVisible" && Primary
                    }
                    onClick={(e) =>
                      locationRef.current?.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                  >
                    2. Projects
                  </Subtitle1>
                  <Subtitle1
                    color={visibleItersection === "reportsisVisible" && Primary}
                    style={{ marginBottom: "20px" }}
                    onClick={(e) =>
                      reportsRef.current?.scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    3. Performance and Recent Updates
                  </Subtitle1>
                  {media && media.length > 0 && (
                    <Subtitle1
                      style={{ marginBottom: "20px" }}
                      color={visibleItersection === "mediaisVisible" && Primary}
                      onClick={(e) =>
                        mediaRef.current?.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      4. Corporate Documents & Videos
                    </Subtitle1>
                  )}

                  {company.r_r_minerals.length > 0 && (
                    <Subtitle1
                      style={{ marginBottom: "20px" }}
                      color={visibleItersection === "RRisVisible" && Primary}
                      onClick={(e) =>
                        RRRef.current?.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      {media && media.length > 0 ? "5" : "4"}. Resources and
                      Reserves
                    </Subtitle1>
                  )}
                  {company.annual_prod_minerals.length > 0 && (
                    <Subtitle1
                      style={{ marginBottom: "20px" }}
                      color={
                        visibleItersection === "productionisVisble" && Primary
                      }
                      onClick={(e) =>
                        productionRef.current?.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      {media && media.length > 0 ? "6" : "5"}. Production
                    </Subtitle1>
                  )}

                  {members && (
                    <Subtitle1
                      style={{ marginBottom: "20px" }}
                      color={visibleItersection === "boardisVisble" && Primary}
                      onClick={(e) =>
                        boardRef.current?.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      {company.r_r_minerals.length === 0
                        ? "4"
                        : company.annual_prod_minerals.length > 0 &&
                          company.annual_prod_minerals.length > 0
                        ? media && media.length > 0
                          ? "7"
                          : "6"
                        : "5"}
                      . Board Members & Executives
                    </Subtitle1>
                  )}
                </div>
              </div>
            </Grid>
          ) : (
            <Popover
              className="relative m-2 p-4 z-10 "
              style={{ padding: "10px", position: "sticky", top: 0 }}
            >
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",

                      "group p-2 bg-white rounded-md shadow-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 left-full transform -translate-x-1/10 mt-3 px-2 w-screen max-w-xs sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-2 py-2 sm:gap-8 ">
                          <div
                            style={{
                              padding: "5px",
                              position: "sticky",
                              top: 0,
                            }}
                          >
                            <div>
                              <div className="flex-boy-row w-full">
                                <div
                                  className="mx-2"
                                  style={{ marginRight: "20px" }}
                                >
                                  <div className="company-ticker">
                                    <Subtitle1
                                      onClick={() =>
                                        getCompanyDetail({
                                          id: company.id,
                                          history,
                                        })
                                      }
                                    >
                                      {company.ticker && company.exchange
                                        ? ` ${company.ticker}.${company.exchange}`
                                        : company.name}
                                    </Subtitle1>

                                    <Body2
                                      onClick={() =>
                                        getCompanyDetail({
                                          id: company.id,
                                          history,
                                        })
                                      }
                                    >
                                      {/* {company.name} //removed because of duplication of company name */}
                                    </Body2>
                                  </div>
                                </div>

                                <div style={{ flex: 1, marginTop: "10px" }}>
                                  <div className="tiny-spark float-right">
                                    <Sparkline
                                      company={company}
                                      color={
                                        trend.trend !== "up"
                                          ? "#B00020"
                                          : "#00AC69"
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="flex-boy-row flex-wrap">
                                <div
                                  className="flex-boy-row mx-2"
                                  style={{ flex: 1, justifyContent: "normal" }}
                                >
                                  {[...new Set(company.minerals)]
                                    .slice(0, 4)
                                    .filter(
                                      (item) =>
                                        item !== "Not" &&
                                        item !== "Combined" &&
                                        item !== "Metric Tonnes"
                                    )
                                    .map((min, index) => {
                                      return (
                                        <Hexagon
                                          key={index}
                                          width={28}
                                          style={{ fontSize: "0.6rem" }}
                                        >
                                          {elements.find(
                                            (item) =>
                                              item.name.toLowerCase() ===
                                              min.trim().toLowerCase()
                                          )
                                            ? elements
                                                .find(
                                                  (item) =>
                                                    item.name.toLowerCase() ===
                                                    min.trim().toLowerCase()
                                                )
                                                .chemical_symbol.substring(0, 4)
                                            : min
                                                .substring(0, 4)
                                                .substring(0, 4)}
                                        </Hexagon>
                                      );
                                    })}
                                </div>
                                <div className="flex-1 m-2">
                                  <GreyBox
                                    className="text-sm float-right"
                                    style={{ width: "100px" }}
                                  >
                                    {company.projects_count} Projects
                                  </GreyBox>
                                </div>
                              </div>
                            </div>
                            <Divider />

                            <RadialChart company={company} />

                            <div
                              className="flex-boy-row flex-wrap"
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                            >
                              <TailwindButton
                                onClick={() =>
                                  activeUser
                                    ? setNotesOpen(true)
                                    : history.push("/auth/login")
                                }
                                text={`${
                                  existingNote ? "Edit" : "+ Add"
                                } Notes`}
                              />
                              {contacts && contacts.length
                                ? contacts.map((item) => {
                                    return (
                                      <SocialsLink
                                        onClick={() => {
                                          if (item.platform !== "email") {
                                            window.open(item.url, "_blank");
                                          }
                                        }}
                                      >
                                        {item.platform === "linkedin" ? (
                                          <LinkedIn fontSize="medium" />
                                        ) : item.platform === "twitter" ? (
                                          <Twitter fontSize="medium" />
                                        ) : item.platform === "email" ? (
                                          <AlternateEmail fontSize="medium" />
                                        ) : item.platform === "instagram" ? (
                                          <Instagram fontSize="medium" />
                                        ) : item.platform === "facebook" ? (
                                          <Facebook fontSize="medium" />
                                        ) : (
                                          <Facebook fontSize="medium" />
                                        )}
                                      </SocialsLink>
                                    );
                                  })
                                : null}
                              <div className="my-2">
                                {activeUser &&
                                activeUser?.permissions?.includes(
                                  "follow_resources"
                                ) ? (
                                  user_follow_list?.includes(company.id) ? (
                                    <TailwindButton
                                      text="Following"
                                      onClick={() => setListOpen(true)}
                                    />
                                  ) : user_follow_list
                                      ?.map((item) => item.list_entries)
                                      ?.flat()
                                      ?.includes(company.id) ? (
                                    <TailwindButton
                                      text="Following"
                                      onClick={() => setListOpen(true)}
                                    />
                                  ) : (
                                    <TailwindButtonOutline
                                      onClick={() => setListOpen(true)}
                                      text="Follow"
                                    />
                                  )
                                ) : (
                                  <TailwindButtonOutline
                                    onClick={() =>
                                      activeUser
                                        ? manageSub()
                                        : history.push("/auth/login")
                                    }
                                    text="Connect"
                                  />
                                )}
                              </div>
                            </div>

                            <div className="page-anchors">
                              <Subtitle1
                                color={
                                  visibleItersection === "overviewisVisible" &&
                                  Primary
                                }
                                style={{ marginBottom: "20px" }}
                                onClick={(e) =>
                                  overviewRef.current?.scrollIntoView({
                                    behavior: "smooth",
                                  })
                                }
                              >
                                1. Company Overview
                              </Subtitle1>
                              <Subtitle1
                                style={{ marginBottom: "20px" }}
                                color={
                                  visibleItersection === "locationisVisible" &&
                                  Primary
                                }
                                onClick={(e) =>
                                  locationRef.current?.scrollIntoView({
                                    behavior: "smooth",
                                  })
                                }
                              >
                                2. Projects
                              </Subtitle1>
                              <Subtitle1
                                color={
                                  visibleItersection === "reportsisVisible" &&
                                  Primary
                                }
                                style={{ marginBottom: "20px" }}
                                onClick={(e) =>
                                  reportsRef.current?.scrollIntoView({
                                    behavior: "smooth",
                                  })
                                }
                              >
                                3. Performance and Recent Updates
                              </Subtitle1>

                              {company.r_r_minerals.length > 0 && (
                                <Subtitle1
                                  style={{ marginBottom: "20px" }}
                                  color={
                                    visibleItersection === "RRisVisible" &&
                                    Primary
                                  }
                                  onClick={(e) =>
                                    RRRef.current?.scrollIntoView({
                                      behavior: "smooth",
                                    })
                                  }
                                >
                                  4. Resources and Reserves
                                </Subtitle1>
                              )}
                              {company.annual_prod_minerals.length > 0 && (
                                <Subtitle1
                                  style={{ marginBottom: "20px" }}
                                  color={
                                    visibleItersection ===
                                      "productionisVisble" && Primary
                                  }
                                  onClick={(e) =>
                                    productionRef.current?.scrollIntoView({
                                      behavior: "smooth",
                                    })
                                  }
                                >
                                  5. Production
                                </Subtitle1>
                              )}
                              <Subtitle1
                                style={{ marginBottom: "20px" }}
                                color={
                                  visibleItersection === "boardisVisble" &&
                                  Primary
                                }
                                onClick={(e) =>
                                  boardRef.current?.scrollIntoView({
                                    behavior: "smooth",
                                  })
                                }
                              >
                                {company.r_r_minerals.length === 0
                                  ? "4"
                                  : company.annual_prod_minerals.length > 0 &&
                                    company.annual_prod_minerals.length > 0
                                  ? "6"
                                  : "5"}
                                . Board Members & Executives
                              </Subtitle1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          )}

          <Grid
            item
            xs={12}
            lg={10}
            xl={10}
            container
            className="company-page-right"
          >
            <ComponentContainer>
              <div
                ref={overviewRef}
                className="flex-boy-row company-page-wrapper"
                style={{ marginTop: "10px", alignItems: "normal" }}
              >
                <div
                  className="flex flex-col justify-between"
                  style={{ flex: 1, textAlign: "left" }}
                >
                  <div className="w-full">
                    <div className="flex-boy-row ">
                      <div className="flex-boy-row">
                        <KeyboardBackspace
                          style={{ color: Primary }}
                          onClick={() => history.goBack()}
                        />
                        <Headline5 style={{ margin: "10px" }}>
                          {company.name}(
                          {company.ticker && company.exchange
                            ? `${company.ticker}.${company.exchange}`
                            : "Private"}
                          )
                        </Headline5>
                      </div>
                    </div>
                    <Body1
                      style={{
                        textAlign: "justify",
                        marginBottom: "10px",
                        marginRight: "20px",
                      }}
                    >
                      {company.description}
                    </Body1>
                  </div>
                  {/* {company.booth && (
                    <div className="flex h-fit p-2 w-full bg-emerald-400 text-white ">
                      <p className="text-lg font-semibold">
                        Located @PDAC Booth:{company.booth}
                      </p>
                    </div>
                  )} */}
                </div>

                {/* {company.ticker && company.exchange ? <div style={{ flex: 1 }}> <PeformanceChartJs color={trend.trend !== 'up' ? "#B00020" : "#00AC69"} /> </div> : null} */}
                <div style={{ flex: 1, marginLeft: "20px" }}>
                  {company.ticker && company.exchange ? (
                    <div
                      className="company-company-detail"
                      style={{ borderLeft: "1px solid gainsboro" }}
                    >
                      {activeUser?.permissions?.includes(
                        "view_company_details"
                      ) ? (
                        company.fundermental_details ? (
                          <div className="flex-boy-row statistics">
                            <div className="statistic">
                              <Subtitle2>Open</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details.open_price,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>Avg Volume (3 month)</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details
                                    .three_months_average_volume,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>Today's High</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details.today_high_price,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>Avg Volume (10 day)</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details
                                    .ten_days_average_volume,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>Today's Low</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details.today_low_price,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>Market Cap</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details.market_cap,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>52 week High</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details.year_high_price,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>P/E Ratio</Subtitle2>
                              <Subtitle1>
                                {" "}
                                {nFormatter(
                                  company.fundermental_details.p_e_ration,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>52 week Low</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details.year_low_price,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>Div/Yield</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details.div_yield,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>% Held by Insiders</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details.held_by_insider,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>% Held by Institutions</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details
                                    .held_by_institutions,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>Shares Outstanding</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details
                                    .shares_outstanding,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                            <div className="statistic">
                              <Subtitle2>Float</Subtitle2>
                              <Subtitle1>
                                {nFormatter(
                                  company.fundermental_details.float,
                                  2
                                ) || "N/A"}
                              </Subtitle1>
                            </div>
                          </div>
                        ) : null
                      ) : (
                        <div className="blur-content w-full h-fit min-h-[500px] relative">
                          <div
                            className="subscription-box absolute left-[50%] top-[50%] z-10"
                            style={{ height: "400px" }}
                          >
                            <div className="sub-box">
                              {activeUser ? (
                                <Subtitle1 color="white">
                                  {" "}
                                  Please upgrade your subscription to view this
                                  content{" "}
                                </Subtitle1>
                              ) : (
                                <Subtitle1>
                                  {" "}
                                  Please Login to view this content{" "}
                                </Subtitle1>
                              )}
                              <Button
                                onClick={() =>
                                  activeUser
                                    ? manageSub()
                                    : history.push("/auth/login")
                                }
                              >
                                {!activeUser ? "Login" : "Upgrade"}
                              </Button>
                            </div>
                          </div>

                          <div className="blur-sm">
                            {statistics &&
                            statistics.heldPercentInstitutions ? (
                              <div className="flex-boy-row statistics">
                                <div className="statistic">
                                  <Subtitle2>Open</Subtitle2>
                                  <Subtitle1>
                                    {statistics.regularMarketOpen}
                                  </Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>Avg Volume (3 month)</Subtitle2>
                                  <Subtitle1>
                                    {statistics.averageDailyVolume3Month}
                                  </Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>Today's High</Subtitle2>
                                  <Subtitle1>
                                    {statistics.regularMarketDayHigh}
                                  </Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>Avg Volume (10 day)</Subtitle2>
                                  <Subtitle1>
                                    {statistics.averageDailyVolume10Day}
                                  </Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>Today's Low</Subtitle2>
                                  <Subtitle1>
                                    {statistics.regularMarketDayLow}
                                  </Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>Market Cap</Subtitle2>
                                  <Subtitle1>{statistics.marketCap}</Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>52 week High</Subtitle2>
                                  <Subtitle1>
                                    {statistics.fiftyTwoWeekHigh}
                                  </Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>P/E Ratio</Subtitle2>
                                  <Subtitle1>A/A</Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>52 week Low</Subtitle2>
                                  <Subtitle1>
                                    {statistics.fiftyTwoWeekLow}
                                  </Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>Div/Yield</Subtitle2>
                                  <Subtitle1>N/A</Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>% Held by Insiders</Subtitle2>
                                  <Subtitle1>
                                    {statistics.heldPercentInsiders?.fmt ||
                                      "N/A"}
                                  </Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>% Held by Institutions</Subtitle2>
                                  <Subtitle1>
                                    {statistics.heldPercentInstitutions?.fmt ||
                                      "N/A"}
                                  </Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>Shares Outstanding</Subtitle2>
                                  <Subtitle1>
                                    {statistics.sharesOutstanding
                                      ? statistics.sharesOutstanding.fmt
                                      : 0}
                                  </Subtitle1>
                                </div>
                                <div className="statistic">
                                  <Subtitle2>Float</Subtitle2>
                                  <Subtitle1>
                                    {statistics.floatShares?.fmt || 0}
                                  </Subtitle1>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </ComponentContainer>
            <ComponentContainer width={"100%"} ref={locationRef}>
              <CompanyProjects
                company={company}
                center={center}
                trend={trend}
              />
            </ComponentContainer>

            <ComponentContainer ref={reportsRef}>
              <StockPerformance />
            </ComponentContainer>

            {media && media.length ? (
              <ComponentContainer ref={mediaRef}>
                <Headline5 style={{ margin: "20px 0 20px 0" }}>
                  Corporate Presentations & Reports
                </Headline5>
                <div
                  className="flex-boy-row"
                  style={{ justifyContent: "normal" }}
                >
                  {media
                    .filter((item) => item.category === "presentation_report")
                    .map((pdf) => (
                      <div
                        onClick={() =>
                          handleOpenDashmodal({
                            type: "pdf",
                            content: pdf.resource_url,
                          })
                        }
                        className="company-content"
                      >
                        <img
                          style={{ width: "220px", height: "150px" }}
                          src={pdf.thumbnail}
                          alt="project"
                        />
                        <Body3>{pdf.title}</Body3>
                      </div>
                    ))}
                </div>
                <Headline5 style={{ margin: "20px 0 20px 0" }}>
                  Video Media
                </Headline5>

                <div className="flex-boy-row">
                  {media
                    .filter((item) => item.category === "videos")
                    .map((video) => (
                      <div
                        onClick={() =>
                          handleOpenDashmodal({
                            type: "video",
                            content: video.resource_url,
                          })
                        }
                        className="company-content"
                      >
                        <div className="iframe-cover"></div>
                        <ReactPlayer
                          width="220px"
                          height="150px"
                          url={video.resource_url}
                        />
                        <Body3>{video.title}</Body3>
                      </div>
                    ))}
                </div>
                <Headline5 style={{ margin: "20px 0 20px 0" }}>
                  Virtual Presentations
                </Headline5>
                <div className="flex-boy-row">
                  <div
                    className="flex-boy-row"
                    style={{ justifyContent: "normal" }}
                  >
                    {media
                      .filter((item) => item.category === "vr_presentation")
                      .map((presentation) => (
                        <div
                          onClick={() =>
                            handleOpenDashmodal({
                              type: "iframe",
                              content: presentation.resource_url,
                            })
                          }
                          className="company-content"
                        >
                          <div className="iframe-cover"></div>

                          <iframe
                            style={{ width: "220px", height: "150px" }}
                            src={presentation.resource_url}
                            title="company frame"
                            frameBorder="0"
                          />
                          <Body3>{presentation.title}</Body3>
                        </div>
                      ))}
                  </div>
                </div>
              </ComponentContainer>
            ) : null}

            {company.r_r_minerals.length > 0 &&
            activeUser?.permissions?.includes(
              "view_resource_reserve_charts"
            ) ? (
              <>
                {!company_loading && company.r_r_minerals.length > 0 && (
                  <ComponentContainer ref={RRRef}>
                    {company.r_r_minerals.length > 0 && (
                      <CompanyResources company={company} />
                    )}
                  </ComponentContainer>
                )}

                {!company_loading &&
                  (company.annual_prod_minerals.length > 0 ||
                    company.quarterly_prod_minerals.length > 0) && (
                    <ComponentContainer ref={productionRef}>
                      <Headline5>Production</Headline5>
                      {/* <div className="flex flex-row  min-w-[50%] mt-4">
                        {company &&
                          _.uniq([
                            ...company.annual_prod_minerals,
                            ...company.quarterly_prod_minerals,
                          ])
                            .splice(0, 10)
                            .map((min) => {
                              let mineral = elements.find(
                                (item) =>
                                  item.name.toLowerCase() ===
                                  min.trim().toLowerCase()
                              )
                                ? elements
                                    .find(
                                      (item) =>
                                        item.name.toLowerCase() ===
                                        min.trim().toLowerCase()
                                    )
                                    .chemical_symbol.substring(0, 3)
                                : min.substring(0, 3);

                              return (
                                <div
                                  className={`mineral-div ${
                                    selected_mineral === min
                                      ? "bg-emerald-500"
                                      : "bg-slate-200"
                                  } cursor-pointer flex-boy-row h-[50px] w-[50px] mr-[20px] font-bold rounded`}
                                  onClick={() => setselected_mineral(min)}
                                >
                                  <Headline6
                                    style={{ textAlign: "center", flex: "1" }}
                                    color={
                                      selected_mineral === min
                                        ? "white"
                                        : "grey"
                                    }
                                  >
                                    {mineral}{" "}
                                  </Headline6>
                                </div>
                              );
                            })}
                      </div> */}
                      {company.annual_prod_minerals.length > 0 && (
                        <CompanyProduction mineral={selected_mineral} />
                      )}
                      {company.quarterly_prod_minerals.length > 0 && (
                        <QurterlyCompanyProduction mineral={selected_mineral} />
                      )}
                    </ComponentContainer>
                  )}
              </>
            ) : company.r_r_minerals.length > 0 ? (
              <div className="blur-content w-full h-fit min-h-[500px] relative">
                <div
                  className="subscription-box absolute left-[50%] top-[50%] z-10"
                  style={{ height: "400px" }}
                >
                  <div className="sub-box">
                    {activeUser ? (
                      <Subtitle1 color="white">
                        {" "}
                        Please upgrade your subscription to view this content{" "}
                      </Subtitle1>
                    ) : (
                      <Subtitle1> Please Login to view this content </Subtitle1>
                    )}
                    <Button
                      onClick={() =>
                        activeUser
                          ? history.push("/upgrade")
                          : history.push("/auth/login")
                      }
                    >
                      {!activeUser ? "Login" : "Upgrade"}
                    </Button>
                  </div>
                </div>
                <div className="blur w-full">
                  {!company_loading && company.r_r_minerals.length > 0 && (
                    <ComponentContainer ref={RRRef}>
                      {company.r_r_minerals.length > 0 && (
                        <CompanyResources company={company} />
                      )}
                    </ComponentContainer>
                  )}

                  {!company_loading &&
                    (company.annual_prod_minerals.length > 0 ||
                      company.quarterly_prod_minerals.length > 0) && (
                      <ComponentContainer ref={productionRef}>
                        <Headline5>Production</Headline5>
                        {/* <div className="flex flex-row  min-w-[50%] mt-4">
                          {company &&
                            _.uniq([
                              ...company.annual_prod_minerals,
                              ...company.quarterly_prod_minerals,
                            ])
                              .splice(0, 10)
                              .map((min) => {
                                let mineral = elements.find(
                                  (item) =>
                                    item.name.toLowerCase() ===
                                    min.trim().toLowerCase()
                                )
                                  ? elements
                                      .find(
                                        (item) =>
                                          item.name.toLowerCase() ===
                                          min.trim().toLowerCase()
                                      )
                                      .chemical_symbol.substring(0, 3)
                                  : min.substring(0, 3);

                                return (
                                  <div
                                    className={`mineral-div ${
                                      selected_mineral === min
                                        ? "bg-emerald-500"
                                        : "bg-slate-200"
                                    } cursor-pointer flex-boy-row h-[50px] w-[50px] mr-[20px] font-bold rounded`}
                                    onClick={() => setselected_mineral(min)}
                                  >
                                    <Headline6
                                      style={{ textAlign: "center", flex: "1" }}
                                      color={
                                        selected_mineral === min
                                          ? "white"
                                          : "grey"
                                      }
                                    >
                                      {mineral}{" "}
                                    </Headline6>
                                  </div>
                                );
                              })}
                        </div> */}
                        {company.annual_prod_minerals.length > 0 && (
                          <CompanyProduction mineral={selected_mineral} />
                        )}
                        {company.quarterly_prod_minerals.length > 0 && (
                          <QurterlyCompanyProduction
                            mineral={selected_mineral}
                          />
                        )}
                      </ComponentContainer>
                    )}
                </div>
              </div>
            ) : null}
            {members && (
              <ComponentContainer ref={boardRef}>
                <BoardMembers company={company} members={members} />
              </ComponentContainer>
            )}

            <ComponentContainer>
              <CompanyFinancialStatements />
            </ComponentContainer>
          </Grid>

          <Grid item display={{ md: "none", sm: "none" }}></Grid>
        </Grid>
      ) : null}
    </MainPage>
  );
};

const mapStateToProps = (state) => ({
  company: state.authReducer.selectedCompany,
  company_loading: state.authReducer.company_loading,
  news_feed: state.authReducer.selectedCompany_news,
  social_feed: state.authReducer.selectedCompany_social,
  user_follow_list: state.authReducer.lists,
  activeUser: state.authReducer.activeUser,
  statistics: state.authReducer.commodity_stock,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanySocial: (data) => dispatch(getCompanySocial(data)),
  getCompanyNews: (data) => dispatch(getCompanyNews(data)),
  setCommodity: (data) => dispatch({ type: "SET_COMMODITY", payload: data }),
  getCommodityData: (data) => dispatch(getCommpanyStockData(data)),

  setSubscription: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_STATUS", payload: data }),
  getCompanyDetail: (data) => dispatch(getCompanyDetail(data)),
  matchUsers: (data) => dispatch(matchUsers(data)),
  companyConnect: (data) => dispatch(companyConnect(data)),
  setCompany: (data) => dispatch({ type: "SET_COMPANY", payload: data }),
  manageSub: (data) => dispatch(manageSub(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetail);
