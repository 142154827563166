import React, { createContext, useState, useContext } from "react";

const ChatContext = createContext({
  currentConversationId: null,
  setCurrentConversationId: () => {},
  messages: [],
  setMessages: () => {},
  isCreatingNewChat: false,
  setIsCreatingNewChat: () => {},
  conversationHistory: [],
  setConversationHistory: () => {},
});

export function ChatProvider({ children }) {
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isCreatingNewChat, setIsCreatingNewChat] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  return (
    <ChatContext.Provider
      value={{
        currentConversationId,
        setCurrentConversationId,
        messages,
        setMessages,
        isCreatingNewChat,
        setIsCreatingNewChat,
        conversationHistory,
        setConversationHistory,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export function useChatContext() {
  return useContext(ChatContext);
}
