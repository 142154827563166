import React, { useState, useEffect, useRef } from "react";
import * as CPS from "../ChatPage.styles";
import { useChatContext } from "../ChatContext/ChatContext";
import HandshakeIcon from "@mui/icons-material/Handshake";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import BarChartIcon from "@mui/icons-material/BarChart";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { FaFilter } from "react-icons/fa";
import Markdown from "./Markdown";
import FiltersPopup from "./FiltersPopup";
import { Select, MenuItem, Tooltip } from "@mui/material";
import { agentTypes } from "./filterConstants";


const API_HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
};

const BottomSection = ({
  inputText,
  setInputText,
  handleSendMessage,
  handleKeyPress,
  setIsFilterOpen,
  predefinedPrompts,
  displayPromptArea,
  selectedAgent,
  setSelectedAgent,
}) => {
  const inputRef = useRef(null);

  return (
    <CPS.BottomSection>
      {displayPromptArea && (
        <CPS.PromptArea>
          {predefinedPrompts.map((prompt, index) => (
            <CPS.PromptButton
              key={index}
              onClick={() => handleSendMessage(prompt.content)}
            >
              <CPS.PromptIcon>{prompt.icon}</CPS.PromptIcon>
              {prompt.title}
            </CPS.PromptButton>
          ))}
        </CPS.PromptArea>
      )}
      <CPS.InputArea>
      <Select 
        style={{ 
          marginRight: '10px',
          width: '150px',
        }}
        defaultValue={selectedAgent}
        variant="standard"
        disableUnderline
        onChange={(e) => {
          setSelectedAgent(e.target.value);
        }}
        value={selectedAgent}
        label="Agent Type"
        >
          {agentTypes
            .sort((a, b) => {
              if (a.name === "Default Agent") return -1;
              if (b.name === "Default Agent") return 1;
              return a.name.localeCompare(b.name);
            })
            .map((type) => (
              <MenuItem key={type.name} value={type}>
                  <Tooltip title={type.description} arrow placement="left">
                    {type.name}
                  </Tooltip>
                </MenuItem>
            ))}
        </Select>
        <CPS.FilterButton onClick={() => setIsFilterOpen(true)}>
          <FaFilter />
        </CPS.FilterButton>
        <CPS.Input
          ref={inputRef}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyUp={handleKeyPress}
          placeholder="Type your message here..."
        />
        <CPS.SendButton onClick={() => handleSendMessage(inputText)}>
          ➤
        </CPS.SendButton>
      </CPS.InputArea>
    </CPS.BottomSection>
  );
};

const ChatContainer = () => {
  const {
    currentConversationId,
    setCurrentConversationId,
    isCreatingNewChat,
    setIsCreatingNewChat,
    conversationHistory,
    setConversationHistory,
  } = useChatContext();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const [messageStatus, setMessageStatus] = useState("");
  const chatMessagesRef = useRef(null);
  const [inputText, setInputText] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    countries: [],
    minerals: [],
    projectStages: [],
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 12))
      .toISOString()
      .split("T")[0], // 12 months ago
    endDate: new Date().toISOString().split("T")[0], // today
    agentType: selectedAgent,
  });

  const handleApplyFilters = (filters) => {
    setSelectedFilters(filters);
  };

  const predefinedPrompts = [
    {
      title: "Mergers and Acquisitions",
      icon: <HandshakeIcon />,
      content: "Tell me about the latest merger and acquisition activity",
    },
    {
      title: "Latest Drill Results & Highlights",
      icon: <TrackChangesIcon />,
      content: "Show me the latest drill result intercepts",
    },
    {
      title: "Latest Technical Reports",
      icon: <BarChartIcon />,
      content:
        "Give me a synopsis of all the technical reports recently released",
    },
    {
      title: "Production results and updates",
      icon: <PrecisionManufacturingIcon />,
      content: "What are the latest mine production results",
    },
  ];

  const handleSendMessage = (text) => {
    if (text.trim() === "") return;
    const allMessages = [...messages, { content: text, role: "user" }];
    setMessages(allMessages);
    setInputText("");
    setIsLoading(true);

    sendMessage(currentConversationId, text);
  };

  const sendMessage = async (conversation_id, content) => {
    try {
      const resp = await fetch(`${API_HOSTNAME}/chat/message`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          conversation_id: conversation_id,
          content: content,
          attachments: [],
          timestamp: 0,
          filters: {
            minerals:
              selectedFilters.minerals.length > 0
                ? selectedFilters.minerals
                : ["All"],
            countries:
              selectedFilters.countries.length > 0
                ? selectedFilters.countries
                : ["All"],
            stages:
              selectedFilters.projectStages.length > 0
                ? selectedFilters.projectStages
                : ["All"],
          },
          startDate: selectedFilters.startDate || "",
          endDate: selectedFilters.endDate || "",
          agent_type: {
            name: selectedAgent.name,
            system_instruction: selectedAgent.system_instruction,
          },
        }),
      });
      if (!resp.ok) {
        throw new Error(`HTTP error! status: ${resp.status}`);
      }

      const reader = resp.body.getReader();
      const decoder = new TextDecoder();
      let streamComplete = false;
      let isFirstChunk = true;
      let accumulatedContent = "";

      while (!streamComplete) {
        const { done, value } = await reader.read();
        if (done) {
          streamComplete = true;
          continue;
        }

        const chunk = decoder.decode(value);

        if (chunk.startsWith("MSGSTATUS:")) {
          setMessageStatus(chunk.split("MSGSTATUS:")[1]);
        } else {
          accumulatedContent += chunk.replace(/([a-zA-Z])\s+:/g, "$1:");
          if (isFirstChunk) {
            setMessages((currentMessages) => [
              ...currentMessages,
              {
                content: accumulatedContent,
                role: "assistant",
              },
            ]);
            isFirstChunk = false;
            setIsLoading(false);
            setMessageStatus("");
          } else {
            setMessages((currentMessages) => {
              const updatedMessages = [...currentMessages];
              updatedMessages[updatedMessages.length - 1] = {
                content: accumulatedContent,
                role: "assistant",
              };
              return updatedMessages;
            });
          }
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage(inputText);
    }
  };

  const chatStartNew = async () => {
    try {
      setIsCreatingNewChat(true);
      const resp = await fetch(`${API_HOSTNAME}/chat/start_new`, {
        method: "POST",
        headers: getHeaders(),
        credentials: "include",
      });
      if (!resp.ok) {
        throw new Error(`HTTP error! status: ${resp.status}`);
      }

      const data = await resp.json();

      setConversationHistory((conversationHistory) => [
        data,
        ...conversationHistory,
      ]);

      setCurrentConversationId(data.conversation_id);
    } catch (error) {
      console.error("Error starting chat:", error);
    } finally {
      setIsCreatingNewChat(false);
    }
  };

  const newConversation = () => {
    chatStartNew();
  };

  const getConversationData = async (conversation_id) => {
    setIsFetchingMessages(true);
    const resp = await fetch(
      `${API_HOSTNAME}/chat/messages?conversation_id=${conversation_id}`,
      {
        method: "GET",
        headers: getHeaders(),
      }
    );

    const data = await resp.json();
    setMessages(data.messages);
    setIsFetchingMessages(false);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      if (chatMessagesRef.current) {
        chatMessagesRef.current.scrollTop =
          chatMessagesRef.current.scrollHeight;
      }
    });
  }, [messages]);

  useEffect(() => {
    if (currentConversationId == null) {
      setMessages([]);
    } else if (isCreatingNewChat && currentConversationId != null) {
      setMessages([]);
      setIsCreatingNewChat(false);
    } else {
      getConversationData(currentConversationId);
    }
  }, [currentConversationId]);

  useEffect(() => {
    const defaultAgent = agentTypes.find(
      (agent) => agent.name === "Default Agent"
    );
    setSelectedAgent(defaultAgent);
  }, []);

  return (
    <CPS.ChatArea>
      {currentConversationId && !isCreatingNewChat ? (
        <>
          {isFetchingMessages ? (
            <>
              <CPS.LoadingContainer>
                <CPS.LoadingWheel />
                <CPS.LoadingText>Loading messages...</CPS.LoadingText>
              </CPS.LoadingContainer>
              <BottomSection
                inputText={inputText}
                setInputText={setInputText}
                handleSendMessage={handleSendMessage}
                handleKeyPress={handleKeyPress}
                setIsFilterOpen={setIsFilterOpen}
                predefinedPrompts={predefinedPrompts}
                displayPromptArea={messages.length == 0}
                selectedAgent={selectedAgent}
                setSelectedAgent={setSelectedAgent}
              />
            </>
          ) : (
            <>
              <CPS.ChatMessages ref={chatMessagesRef}>
                {messages.map((msg, index) => (
                  <CPS.Message key={index} role={msg.role}>
                    <Markdown>{msg.content}</Markdown>
                  </CPS.Message>
                ))}
                {isLoading && <CPS.Ellipsis>{messageStatus}...</CPS.Ellipsis>}
              </CPS.ChatMessages>
              <BottomSection
                inputText={inputText}
                setInputText={setInputText}
                handleSendMessage={handleSendMessage}
                handleKeyPress={handleKeyPress}
                setIsFilterOpen={setIsFilterOpen}
                predefinedPrompts={predefinedPrompts}
                displayPromptArea={messages.length == 0}
                selectedAgent={selectedAgent}
                setSelectedAgent={setSelectedAgent}
              />
              <FiltersPopup
                open={isFilterOpen}
                onClose={() => setIsFilterOpen(false)}
                onApplyFilters={handleApplyFilters}
                selectedAgent={selectedAgent}
              />
            </>
          )}
        </>
      ) : isCreatingNewChat ? (
        <>
          <CPS.LoadingContainer>
            <CPS.LoadingWheel />
            <CPS.LoadingText>Creating new chat...</CPS.LoadingText>
          </CPS.LoadingContainer>
          <BottomSection
            inputText={inputText}
            setInputText={setInputText}
            handleSendMessage={handleSendMessage}
            handleKeyPress={handleKeyPress}
            setIsFilterOpen={setIsFilterOpen}
            predefinedPrompts={predefinedPrompts}
            selectedAgent={selectedAgent}
            setSelectedAgent={setSelectedAgent}
          />
        </>
      ) : (
        <CPS.CenteredContainer>
          <CPS.NewChatButton onClick={newConversation}>
            Start New Chat
          </CPS.NewChatButton>
        </CPS.CenteredContainer>
      )}
    </CPS.ChatArea>
  );
};

export default ChatContainer;
