import styled, { keyframes } from "styled-components";

export const DeleteConfirmationPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

export const DeleteConfirmButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export const DeleteCancelButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const LoadingWheel = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
`;

export const LoadingText = styled.div`
  margin-left: 10px;
  font-size: 16px;
  color: #000;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const NewChatButton = styled.button`
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0052a3;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.5);
  }
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0 5px;
  color: #ff4d4d;
`;

export const EditNameForm = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const EditNameInput = styled.input`
  flex: 1;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const EditNameButton = styled.button`
  padding: 5px 10px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 5px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  .spinner {
    animation: ${spin} 1s linear infinite;
  }
`;

export const ConversationName = styled.span`
  flex: 1;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  position: relative;

  &:hover::after {
    content: attr(data-full-text);
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    z-index: 1;
    white-space: normal;
    max-width: 200px;
    word-wrap: break-word;
  }
`;

export const EditButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0 5px;
`;

export const ChatPageContainer = styled.div`
  display: flex;
  height: 90vh;
  font-family: Arial, sans-serif;
  overflow: hidden;
`;

export const Sidebar = styled.div`
  width: 300px;
  background-color: #f7f7f8;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e0e0e0;
`;

export const SearchBar = styled.input`
  width: calc(100% - 40px);
  padding: 10px;
  margin: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
`;

export const HistoryContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;
`;

export const HistorySection = styled.div`
  margin-bottom: 20px;
`;

export const HistoryTitle = styled.h3`
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
`;

export const HistoryItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  font-size: 12px;
  background-color: ${(props) =>
    props.isSelected ? "rgba(0, 0, 0, 0.05)" : "transparent"};
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #e0e0e0;
  }
`;

export const ChatIcon = styled.span`
  margin-right: 10px;
`;

export const ChatArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

export const Message = styled.div`
  background-color: ${(props) =>
    props.role === "user" ? "#e6f2ff" : "#f0f0f0"};
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  // display: block;
  text-align: ${(props) => (props.role === "user" ? "right" : "left")};
  // word-wrap: break-word;
  // white-space: pre-wrap;
  float: ${(props) => (props.role === "user" ? "right" : "left")};
  clear: both;

  h3 {
    color: #333;
    font-size: 1.2em;
  }

  ul {
    padding-left: 1em;
    list-style-type: disc;
  }

  li {
    margin: 0.1em 0 0 0;
    display: list-item;
  }

  a {
    color: #0066cc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  a[href*="filing-detail"] {
    display: block;
    font-size: 0.9em;
    color: #666;
    // margin: 0.25em 0 0.5em 0;
  }

  strong {
    display: block;
    font-weight: bold;
  }

  p {
    line-height: 1.5;
  }
`;

export const MarkdownWrapper = styled.div`
  * {
    all: revert;
  }
  p {
    margin: 1em 0;
    font-size: 16px;
    line-height: 1.5;
  }

  a,
  a:visited,
  a:link {
    color: #1a9bdb !important; /* Use important if needed */
    text-decoration: none;
    cursor: pointer;
    pointer-events: auto !important; /* Ensure clickability */
  }

  /* Hover state */
  a:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  a[href*="http"],
  a[href*="https"] {
    color: #1a9bdb !important;
    display: inline-block;
    pointer-events: auto !important;
  }
`;

export const Ellipsis = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: block;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-wrap;
  float: left;
  clear: both;
  font-size: 1em;
  margin: 10px 0;
`;

export const BottomSection = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
`;

export const PromptArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
`;

export const PromptButton = styled.button`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
`;

export const PromptIcon = styled.span`
  margin-right: 10px;
  font-size: 20px;
`;

export const InputArea = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const AttachmentButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
`;

export const FilterButton = styled(AttachmentButton)`
  // Inherits styles from AttachmentButton
`;

export const Input = styled.input`
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const SendButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #0066cc;
`;

export const DateHeader = styled.div`
  padding: 8px 16px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #666;
  background-color: #f5f5f5;
`;
