/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";

import CacheBuster from "./CacheBuster";
import "./App.css";
import { useTranslation } from "react-i18next";
import "./translations/i18n";
import GlobalFonts from "./assets/fonts/fonts";
import Main from "./Main";
import { Switch, Route, useLocation } from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import algoliasearch from "algoliasearch/lite";
import firebase from "./services/firebase";
import { withRouter } from "react-router";
// const messaging = firebase.messaging()
import ReactGA from "react-ga4";
const analytics = firebase.analytics;
import MomentUtils from "@date-io/moment";
import ReactDOM from "react-dom";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts-fix";
import { Auth0Provider } from "@auth0/auth0-react";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

ReactFC.fcRoot(FusionCharts, Charts);

FusionCharts.options.license({
  key: "KqA8ydjB3B3E2A3A16B10A8E6D6E4C1H2C1ixrB1F2gcowc1B6A7A-13mB-9A3H2C2B2C1E7A6E1E5F4A1C3B9B2D3D2ippE1C3C11d1C7A3B4utcH3B5D7moB-9D3C4D2H-7geE3A3B2F4H2D3H2C8B1E4B1zllA33A8a1B9d1dB-11G4F4D3jyA7RA1E3wwi1A1A7A4B6B5F5E4A2G3J3A5B4D3C7E-11==",
  creditLabel: false,
});

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-062025}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Prospector}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Prospector}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Prospector}_need_to_be_licensed___{Prospector}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{19_June_2025}____[v3]_[01]_MTc1MDI4NzYwMDAwMA==fbd1f8390a307f4e6dee2d2376e378d1"
);

const TRACKING_ID = "G-JB0KC590Z4";

function App() {
  const { t } = useTranslation();
  const firestore = useFirestore();

  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    // if (messaging.isSupported()) {
    //   messaging.requestPermission()
    //     .then(async function () {
    //       const token = await messaging.getToken();
    //
    //     })
    //     .catch(function (err) {
    //
    //     });
    //   navigator.serviceWorker.addEventListener("message", (message) =>
    // }

    return () => {};
  }, []);
  const [authenticated, setauthenticated] = useState(false);
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <div className="App">
            <ToastContainer theme="light" />
            <GlobalFonts />
            {/* <header className="App-header">
                <h1>Cache Busting - {t("Example")}</h1>
                <p>
                  Bundle version - <code>v{global.appVersion}</code>
                </p>
              </header> */}
            <Auth0Provider
              domain={process.env.REACT_APP_AUTH0_DOMAIN}
              clientId={process.env.REACT_APP_CLIENT_ID}
              authorizationParams={{
                redirect_uri: "window.location.origin",
              }}
              onRedirectCallback={(appState) => {
                if (appState) {
                  setauthenticated(true);
                }
              }}
            >
              <Main authenticated={authenticated} />
            </Auth0Provider>
          </div>
        );
      }}
    </CacheBuster>
  );
}

export default App;
